import { get, set } from 'lodash';

import { actionTypes as callAgentActionTypes } from '../../actions/callAgentWorkplace';
import { replaceAt } from '../../../utils';
import { CAWP_TABLE_NAME_KEYS } from '../../../constants';

const initialState = {
  currentTable: CAWP_TABLE_NAME_KEYS.B2C,
  isLoading: false,
  filters: {
    visible: true,
    preset: {
      // preset buttons:
      finished: false,
      activated: false,
      invoice_sent: '0',
      signup: false,
      // name + surname substr search
      search: '',
      isSearchSeparated: false,
    },
    sorted: [],
  },
  offset: 0,
  total: 0,
  limit: 100,
  data: [],
  error: null,
  extendedRowData: null,
  isRowDataLoading: false,
};

const callAgentWorkplaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case callAgentActionTypes.GET_DATA_ASYNC_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case callAgentActionTypes.GET_DATA_ASYNC_SUCCESS: {
      const {
        payload: { data, offset, total, limit },
      } = action;
      return {
        ...state,
        isLoading: false,
        data,
        total,
        offset,
        limit,
      };
    }
    case callAgentActionTypes.GET_DATA_ASYNC_ERROR: {
      const error = get(action.payload, 'error', 'Unknown error');
      return {
        ...state,
        isLoading: false,
        data: [],
        error,
      };
    }
    case callAgentActionTypes.GET_EXT_ROW_DATA: {
      const { rowId } = action;
      const { data } = state;
      const extendedRowData = data.find(({ id }) => id === rowId);
      return {
        ...state,
        extendedRowData: {
          ...extendedRowData,
          contact_history: extendedRowData.user.contact_history,
        },
        isRowDataLoading: true,
      };
    }
    case callAgentActionTypes.UPDATE_ROW_DATA: {
      const { data: updatedData, path } = action;
      const { extendedRowData, data } = state;
      const updatedExtRow = set(extendedRowData, path, updatedData);

      const rowIndex = data.findIndex(({ id }) => id === extendedRowData.id);
      if (rowIndex === -1) {
        console.error('Unable to find row index');
        return state;
      }

      const updRow = { ...data[rowIndex], ...updatedExtRow };

      return {
        ...state,
        data: replaceAt(data, rowIndex, updRow),
        extendedRowData: updatedExtRow,
      };
    }
    case callAgentActionTypes.ADD_SERVICE_ENTRY_ASYNC_SUCCESS:
    case callAgentActionTypes.REMOVE_SERVICE_ENTRY_ASYNC_SUCCESS:
    case callAgentActionTypes.UPDATE_SERVICE_ENTRY_ASYNC_SUCCESS:
    case callAgentActionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_SUCCESS:
    case callAgentActionTypes.DELETE_PRESENTATION_PRODUCT_ASYNC_SUCCESS:
    case callAgentActionTypes.EDIT_HISTORY_ASYNC_SUCCESS:
    case callAgentActionTypes.ADD_HISTORY_ASYNC_SUCCESS: {
      const {
        payload: { data: updatedData },
      } = action;
      const { data, extendedRowData } = state;

      const rowId = get(extendedRowData, 'id');
      if (!rowId) return state;

      const rowIndex = data.findIndex(({ id }) => id === rowId);
      if (rowIndex === -1) {
        console.error('Unable to find row index');
        return state;
      }
      const updRow = { ...data[rowIndex], ...updatedData };
      return {
        ...state,
        isRowDataLoading: false,
        data: replaceAt(data, rowIndex, updRow),
        extendedRowData: {
          ...state.extendedRowData,
          ...updatedData,
        },
      };
    }
    case callAgentActionTypes.EDIT_ACCOUNT_ASYNC_SUCCESS: {
      const {
        payload: { data: account },
      } = action;
      const { data, extendedRowData } = state;

      const rowId = get(extendedRowData, 'id');
      if (!rowId) return state;

      const rowIndex = data.findIndex(({ id }) => id === rowId);
      if (rowIndex === -1) {
        console.error('Unable to find row index');
        return state;
      }
      const updatedData = { user: { ...data[rowIndex].user, account } };
      const updRow = { ...data[rowIndex], ...updatedData };
      return {
        ...state,
        isRowDataLoading: false,
        data: replaceAt(data, rowIndex, updRow),
        extendedRowData: {
          ...state.extendedRowData,
          ...updatedData,
        },
      };
    }
    case callAgentActionTypes.ADD_SERVICE_ENTRY_ASYNC_ERROR:
    case callAgentActionTypes.REMOVE_SERVICE_ENTRY_ASYNC_ERROR:
    case callAgentActionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_ERROR:
    case callAgentActionTypes.EDIT_ACCOUNT_ASYNC_ERROR:
    case callAgentActionTypes.EDIT_HISTORY_ASYNC_ERROR:
    case callAgentActionTypes.ADD_HISTORY_ASYNC_ERROR: {
      return {
        ...state,
        isRowDataLoading: true,
        error: action.error,
      };
    }
    case callAgentActionTypes.SET_FILTERS_VISIBILITY: {
      return {
        ...state,
        filters: {
          ...state.filters,
          visible: action.visible,
        },
      };
    }
    case callAgentActionTypes.CHANGE_CURRENT_TABLE:
      return {
        ...state,
        filters: initialState.filters,
        currentTable: action.tableName,
      };
    case callAgentActionTypes._CHANGE_PAGE: {
      return {
        ...state,
        offset: action.pageIndex * 100,
      };
    }
    case callAgentActionTypes._CHANGE_SORTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          sorted: action.sorted,
        },
      };
    case callAgentActionTypes._CHANGE_PRESET_FILTER: {
      const {
        filter: { name, value },
      } = action;
      return {
        ...state,
        filters: {
          ...state.filters,
          preset: {
            ...state.filters.preset,
            [name]: value,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default callAgentWorkplaceReducer;
