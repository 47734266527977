import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  GET_SLIDES_ASYNC_START: null,
  GET_SLIDES_ASYNC_SUCCESS: null,
  GET_SLIDES_ASYNC_ERROR: null,
  GET_VIDEOS_ASYNC_START: null,
  GET_VIDEOS_ASYNC_SUCCESS: null,
  GET_VIDEOS_ASYNC_ERROR: null,
  GET_AUDIOS_ASYNC_START: null,
  GET_AUDIOS_ASYNC_SUCCESS: null,
  GET_AUDIOS_ASYNC_ERROR: null,
  GET_DOCS_ASYNC_START: null,
  GET_DOCS_ASYNC_SUCCESS: null,
  GET_DOCS_ASYNC_ERROR: null,
});

export default {
  getSlides: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_SLIDES_ASYNC_START });
    return api.Academy.getSlides().then(
      payload => dispatch({ type: actionTypes.GET_SLIDES_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_SLIDES_ASYNC_ERROR, error }),
    );
  },
  getVideos: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_VIDEOS_ASYNC_START });
    return api.Academy.getVideos().then(
      payload => dispatch({ type: actionTypes.GET_VIDEOS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_VIDEOS_ASYNC_ERROR, error }),
    );
  },
  getAudios: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_AUDIOS_ASYNC_START });
    return api.Academy.getAudios().then(
      payload => dispatch({ type: actionTypes.GET_AUDIOS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_AUDIOS_ASYNC_ERROR, error }),
    );
  },
  getDocs: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_DOCS_ASYNC_START });
    return api.Academy.getDocs().then(
      payload => dispatch({ type: actionTypes.GET_DOCS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_DOCS_ASYNC_ERROR, error }),
    );
  },
};
