import { createTheme, adaptV4Theme } from '@mui/material/styles';

import { backgroundColor, slateColor } from '../assets/jss/shared';

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        light: '#6ea6e7',
        main: '#4a90e2',
        dark: '#33649e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ed4b82',
        main: '#e91e63',
        dark: '#a31545',
        contrastText: '#fff',
      },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff',
      },
      background: {
        default: backgroundColor,
      },
      text: { secondary: '#999999', light: '#FFF' },
    },
    typography: {
      useNextVariants: true,
      caption: {
        fontWeight: 400,
        fontSize: 14,
      },
      body1: {
        fontWeight: 700,
        fontSize: 16,
      },
      body2: {
        fontWeight: 300,
        fontSize: 16,
      },
      h1: {
        fontSize: 48,
        fontWeight: 700,
        letterSpacing: 'normal',
      },
      h2: {
        fontWeight: 300,
        fontSize: 22,
      },
      h3: {
        fontWeight: 700,
        fontSize: 18,
      },
      h4: {
        fontWeight: 500,
        fontSize: 18,
      },
      h5: {
        fontWeight: 400,
        fontSize: 14,
      },
      h6: {
        fontWeight: 300,
        fontSize: 14,
        color: '#999999',
      },
    },
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: '0.8em',
        },
      },
      MuiPaper: {
        root: {
          borderRadius: 4,
        },
      },
      MuiSelect: {
        selectMenu: {
          height: '1.1875em',
        },
      },
      MuiBadge: {
        anchorOriginTopRightRectangle: {
          top: 8,
          right: 8,
        },
        badge: {
          right: 5,
          top: 10,
          height: 32,
          padding: '0 13px',
          fontSize: 12,
          borderRadius: 26,
          backgroundColor: slateColor,
          color: '#FFF',
        },
        dot: {
          height: 34,
          width: 34,
          padding: '0 16px',
          borderRadius: 20,
        },
      },
      MuiDialogContent: {
        root: {
          padding: '25px 25px 25px',
        },
      },
      MuiChip: {
        root: {
          height: 27,
        },
        avatar: {
          height: 27,
          width: 27,
          fontSize: 14,
          fontWeight: 700,
        },
        colorPrimary: {
          color: '#FFF',
          backgroundColor: slateColor,
          '&:hover': {
            backgroundColor: '#4a90e2',
          },
        },
        avatarColorPrimary: {
          backgroundColor: '#4a90e2',
        },
      },
      MuiFormLabel: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
      MuiIcon: {
        fontSizeSmall: 17,
        fontSizeLarge: 50,
      },
      MuiButton: {
        textPrimary: {
          textTransform: 'capitalize',
          fontWeight: 700,
        },
      },
      MuiSvgIcon: {
        colorPrimary: { color: slateColor },
        colorSecondary: {
          color: '#FFFFFF',
        },
      },
      MuiTableRow: {
        root: {
          '&:hover': {
            background: '#efefef',
          },
        },
      },
    },
  }),
);

export default theme;
