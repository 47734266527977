import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  OPEN_SNACKBAR: null,
  CLOSE_SNACKBAR: null,
});

export default {
  openSnackbar:
    (message, type = 'error', iData = null) =>
    dispatch => {
      if (!message) console.error('Provide message in order to open snackbar');
      dispatch({ type: actionTypes.OPEN_SNACKBAR, data: { message, type, iData } });
    },

  closeSnackbar: () => dispatch => dispatch({ type: actionTypes.CLOSE_SNACKBAR }),
};
