import React, { Component } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import CustomSnackbar from '../../components/Extended/CustomSnackbar';
import snackbarActions from '../../core/store/actions/snackbar';
import { withTranslation } from '../../i18n';

/**
 * This snackbar container used as a single entry point for global errors
 */
class SnackbarContainer extends Component {
  handleCloseSnackbar = () => {
    const { dispatch } = this.props;
    dispatch(snackbarActions.closeSnackbar());
  };

  render() {
    const { type, message = '', iData, isOpen, t } = this.props;

    const translatedMsg = iData ? t(message, iData) : message.includes(':') ? message : t(message);
    return (
      <CustomSnackbar
        variant={type}
        open={isOpen}
        message={translatedMsg}
        handleClose={this.handleCloseSnackbar}
      />
    );
  }
}

export default compose(
  withTranslation('common'),
  connect(({ snackbar }, ownProps) => ({ ...snackbar, ...ownProps })),
)(SnackbarContainer);
