import React from 'react';
import { compose } from 'recompose';
import App from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import moment from 'moment';

import { LicenseInfo } from '@mui/x-license-pro';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

import updateServiceWorker from '../core/updateServiceWorker';
import SnackbarContainer from '../containers/Snackbar';
import theme from '../core/theme';

import 'moment/locale/de';
import 'moment/locale/en-gb';

// global scss:
import 'react-datepicker/dist/react-datepicker.css';
import '../assets/jss/global.scss';

// REFACTOR: this is a hack for proper link transition to statistics, as mention in:
//  https://github.com/zeit/next.js/issues/5203#issuecomment-423403716
import '../assets/jss/vendor/react-table.scss';

// other vendor styles import -> maybe it can be converted to Component-Level CSS (CSS Modules)
import '../assets/jss/vendor/embed-doc.scss';

import withReduxStore from '../core/withReduxStore';
import { appWithTranslation, i18n } from '../i18n';
import withAuth from '../core/withAuth';

// default moment locale: (it is required to set it manually)
moment.locale('de');

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_LICENSE);

class _App extends App {
  // more info about getInitialProps:
  // https://github.com/zeit/next.js#custom-app
  // https://spectrum.chat/next-js/general/app-js-getinitialprops-explained~539bdc35-a8b0-4dfa-972b-0dd9a0f1ac67

  // INFO:
  // For the initial page load, getInitialProps will execute on the SERVER only
  // getInitialProps will only be executed on the CLIENT when navigating to a different route via the Link component

  // Only uncomment this method if you have blocking data requirements for
  // every single page in your application. This disables the ability to
  // perform automatic static optimization, causing every page in your app to
  // be server-side rendered.
  static async getInitialProps(appContext) {
    const appProps = await App.getInitialProps(appContext);

    //const reqLanguage = get(ctx, 'req.language');
    // const currentLanguage = reqLanguage || pageProps.currentLanguage;
    return { ...appProps };
  }

  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }

    updateServiceWorker();
  }

  render() {
    const { Component, pageProps, reduxStore, currentLanguage } = this.props;
    return (
      <>
        <Head>
          <title>AVIOR SWISS - Dein persönliches Dashboard</title>

          <link rel="shortcut icon" href="/pwa-icons/favicon.ico" type="image/x-icon" />
          <link rel="icon" href="/pwa-icons/favicon.ico" type="image/x-icon" />
          <link href="https://use.fontawesome.com/releases/v5.0.7/css/all.css" rel="stylesheet" />

          {/* Primary Meta Tags */}
          <meta name="title" content="AVIOR SWISS - Dein persönliches Dashboard" key="title" />
          <meta name="description" content="AVIOR SWISS WEB Application" />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://ev.avior-swiss.com/auth/login" />
          <meta
            property="og:title"
            content="AVIOR SWISS - Dein persönliches Dashboard"
            key="og:title"
          />
          <meta property="og:description" content="" />
          <meta
            property="og:image"
            content="https://ev.avior-swiss.com/static/logo.jpg"
            key="og:image"
          />

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://ev.avior-swiss.com/auth/login" />
          <meta
            property="twitter:title"
            content="AVIOR SWISS - Dein persönliches Dashboard"
            key="twitter:title"
          />
          <meta property="twitter:description" content="" />
          <meta
            property="twitter:image"
            content="https://ev.avior-swiss.com/static/logo.jpg"
            key="twitter:image"
          />
        </Head>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          {/* MuiThemeProvider makes the theme available down the React
            tree thanks to React context. */}
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <Provider store={reduxStore}>
                <>
                  <Component {...pageProps} />
                  <SnackbarContainer />
                </>
              </Provider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </>
    );
  }
}

export default compose(
  withReduxStore,
  appWithTranslation,
  // withAuth,
)(_App);
