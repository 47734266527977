const keyMirror = require('key-mirror');

/**
 * creates 'key mirror' object from object keys
 * @param {object} object - base
 * @return {object} - new key mirror object
 *
 * Example:
 * {ADMIN: 'Admin role'} -> {ADMIN: 'ADMIN'}
 */
const getKeyMirror = object =>
  Object.keys(object).reduce((acc, key) => {
    acc[key] = key;
    return acc;
  }, {});

// REFACTOR: move to shared
const DONT_SAW_REASON = {
  ALREADY_HAS: 'cnst.dont_saw_reason_v.already_has',
  BAD_FINANCE: 'cnst.dont_saw_reason_v.bad_finance',
  WONT_WATCH: 'cnst.dont_saw_reason_v.wont_watch',
  MY_DECISION: 'cnst.dont_saw_reason_v.my decision',
};

const ADDRESS_VALIDATION_COMPONENT_TYPE = {
  route: 'cnst.address_validation.component_type.route',
  locality: 'cnst.address_validation.component_type.locality',
  street_number: 'cnst.address_validation.component_type.street_number',
  postal_code: 'cnst.address_validation.component_type.postal_code',
  country: 'cnst.address_validation.component_type.country',
};

const DIDNT_BUY_REASON = {
  NOT_INTERESTED: 'cnst.didnt_buy_reason.not_interested',
  NOT_ENOUGH_MONEY: 'cnst.didnt_buy_reason.not_enough_money',
  VERY_EXPENSIVE: 'cnst.didnt_buy_reason.very_expensive',
  WIFE_WANTED: 'cnst.didnt_buy_reason.wife_wanted_husband_not',
  HUSBAND_WANTED: 'cnst.didnt_buy_reason.husband_wanted_wife_not',
  NOT_BEST_TIME: 'cnst.didnt_buy_reason.not_best_time',
  NEED_TIME: 'cnst.didnt_buy_reason.need_time_to_think',
  MAYBE_LATER: 'cnst.didnt_buy_reason.maybe_later',
  DONT_APPRECIATE_QUALITY: 'cnst.didnt_buy_reason.dont_appreciate_quality',
  DONT_CARE_ABOUT_CLEAN: 'cnst.didnt_buy_reason.dont_care_about_clean',
  BUJINO_REJECT: 'cnst.didnt_buy_reason.byjino_reject',
  DIDNT_ASSURE: 'cnst.didnt_buy_reason.didnt_manage_to_assure',
};
const DIDNT_BUY_REASON_KEYS = getKeyMirror(DIDNT_BUY_REASON);

const GENDER = {
  MALE: 'cnst.genders.male',
  FEMALE: 'cnst.genders.female',
};

const ROLE = {
  ADMIN: 'cnst.roles.admin',
  USER: 'cnst.roles.user',
  SALES_AGENT: 'cnst.roles.sales_agent',
  CALL_AGENT: 'cnst.roles.call_agent',
  PROM_AGENT: 'cnst.roles.prom_agent',
  PARTNER: 'cnst.roles.partner',
  OCR_MANAGER: 'cnst.roles.ocr_manager',
};

const ROLE_KEYS = getKeyMirror(ROLE);

const PLANNER_DESTINATION_TYPE = getKeyMirror({
  DEMO: null,
  HOME: null,
  PRIVATE: null,
  SERVICE: null,
});

const STATUSES = {
  POTENTIAL: 'cnst.status.potential',
  CALL_AGENTS: 'cnst.status.call_agents',
  UNQUALIFIED: 'cnst.status.unqualified',
  OLDER_THAN_65: 'cnst.status.older',
  YOUNGER_THAN_25: 'cnst.status.younger',
  USERS_REC: 'cnst.status.user_rec',
  UNCONFIRMED: 'cnst.status.unconfirmed',
  POLL_ACTIVE: 'cnst.status.poll_active',
  SALE_AGENTS: 'cnst.status.sale_agents',
  DECLINE_PRESENTATION: 'cnst.status.decline_presentation',
  BUY_PRODUCT: 'cnst.status.buy_product',
  ACCEPT_SERVICE: 'cnst.status.accept_service',
  REJECT_SERVICE: 'cnst.status.reject_service',
  NOT_BUY: 'cnst.status.not_buy',
  BUY_AND_RETURN: 'cnst.status.buy_and_return',
  NOT_INTERESTED: 'cnst.status.not_interested',
  NOT_ANSWERED: 'cnst.status.not_answered',
  WRONG_DATA: 'cnst.status.wrong_data',
  WITHOUT_NUMBER: 'cnst.status.without_number',
  TRASH: 'cnst.status.trash',
  DUPLICATED: 'cnst.status.unsorted',
  KNOW_HULA: 'cnst.status.know_hula',
};

const RECOMMENDATION_STATUS = {
  UNCONFIRMED: 'cnst.recommendation_status.unconfirmed',
  CONFIRMED: 'cnst.recommendation_status.confirmed',
  PENDING: 'cnst.recommendation_status.pending',
  APPROVED: 'cnst.recommendation_status.approved',
  DECLINED: 'cnst.recommendation_status.declined',
  // WRONG_NUMBER: 'cnst.recommendation_status.wrong_number',
  // NOT_INTERESTED: 'cnst.recommendation_status.not_interested',
};

// REFACTOR - get rid of ID maps?
const ROLES_ID_MAP = {
  [ROLE_KEYS.ADMIN]: 'b30a4da2-481e-4281-9a51-6e169b28273e',
  [ROLE_KEYS.USER]: '90313aa6-ff83-437f-8628-f307ecc74598',
  [ROLE_KEYS.SALES_AGENT]: '65dbaa20-dc3d-49e0-bed3-77185de61ee1',
  [ROLE_KEYS.CALL_AGENT]: '39cfd5e8-1be0-4580-ab1c-58908cdffe2b',
  [ROLE_KEYS.PROM_AGENT]: '1982b803-c0e1-44a2-84c7-721a13edea93',
  [ROLE_KEYS.PARTNER]: 'abc41e95-80be-4cb1-b28d-db875784a050',
  [ROLE_KEYS.OCR_MANAGER]: 'be119286-e047-4874-9676-021476b7efa9',
};

const RELATIONS = keyMirror({
  SINGLE: null,
  MARRIED: null,
  PARTNER: null,
});

const CARPET = keyMirror({
  TILE: 'cnst.carpet.tile',
  WOOD: 'cnst.carpet.wood',
  YES: 'cnst.carpet.yes',
  NO: 'cnst.carpet.no',
  OTHER: 'cnst.carpet.other',
});

const PET = {
  CAT: 'cnst.pets.cat',
  NONE: 'cnst.pets.none',
  OTHER: 'cnst.pets.other',
  DOG: 'cnst.pets.dog',
};

const WORKING_STATUSES = keyMirror({
  PART_TIME: null,
  FULL_TIME: null,
  RETIREE: null,
  SEARCH: null,
});

const PROM_GIFTS = keyMirror({
  JASMINE_BLACK: null,
  JASMINE_WHITE: null,
  JASMINE_TITANIUM: null,
  JASMINE_RED: null,
  ZOE_WHITE: null,
  ZOE_BLACK: null,
  WASCHKUGEL: null,
  LUCY_BLACK: null,
  LUCY_WHITE: null,
});

const INITIAL_USER_STATE = {
  id: null,
  role: {
    code: '',
  },
  promotional_gift: null,
  name: '',
  surname: '',
  age: '',
  zipcode: '',
  city: '',
  address: '',
  phone: '',
  mobile_phone: '',
  has_kids: false,
  has_allergies: false,
  email: '',
  // REFACTOR: seems like radio btn fields are lagging after set 'undefined' as a value,
  //  so here we set empty string as default (works OK, but needs investigation)
  // radio btn fields
  pet: '',
  gender: '',
  relations: '',
  working_status: '',
  persons_at_home: '',
  user_info: [],
  sub_user: {
    name: '',
    surname: '',
    age: '',
    zipcode: '',
    city: '',
    address: '',
    phone: '',
    mobile_phone: '',
    email: '',
    company: '',
  },
};

const PRESENTATION_QUALITY = {
  GOOD: 'GOOD',
  BAD: 'BAD',
};

const PRESENTATION_PERIOD = keyMirror({
  FUTURE: null,
  EXPIRED: null,
});

const DASHBOARDS_MAP = {
  [ROLE_KEYS.ADMIN]: 'admin-dashboard',
  [ROLE_KEYS.USER]: 'user-dashboard',
  [ROLE_KEYS.SALES_AGENT]: 'sa-dashboard',
  [ROLE_KEYS.CALL_AGENT]: 'ca-dashboard',
  [ROLE_KEYS.PROM_AGENT]: 'pa-dashboard',
  [ROLE_KEYS.PARTNER]: 'partner-dashboard',
  [ROLE_KEYS.OCR_MANAGER]: 'om-dashboard',
};

//TODO: rename to EVENT_STATUS, since they are used for services also!
const PRESENTATION_STATUS = keyMirror({
  SUCCESS: null,
  CANCELED: null,
  REMEET: null,
  PLANNED: null,
});

const CLEANING_SYSTEMS = keyMirror({
  BAG: null,
  WITHOUT_BAG: null,
  WATER_CLEANING: null,
});

const CLEANING_TARGET = keyMirror({
  MATTRESS: 'cnst.cleaning_target.mattress',
  BACKOFEN: 'cnst.cleaning_target.oven',
  FLOOR: 'cnst.cleaning_target.floor',
  WINDOW: 'cnst.cleaning_target.window',
  KITCHEN: 'cnst.cleaning_target.kitchen',
});

const PRESENTATION_STATUS_LABEL = {
  [PRESENTATION_STATUS.SUCCESS]: 'cnst.presentation.status.success',
  [PRESENTATION_STATUS.CANCELED]: 'cnst.presentation.status.canceled',
  [PRESENTATION_STATUS.REMEET]: 'cnst.presentation.status.remeet',
  [PRESENTATION_STATUS.PLANNED]: 'cnst.presentation.status.planned',
};

const CONTACT_HISTORY_FLOW_TYPE = keyMirror({
  LEAD_FLOW: null, // B2C, B2B table
  BUYER_FLOW: null, // Buyers table
});

const PARTNER_PAYMENT_STATUS = {
  PENDING: 'cnst.partner_payment_status.pending',
  PAID: 'cnst.partner_payment_status.paid',
};

const BITLY = {
  GROUP_ID: 'Blaobj2YUT7',
  DOMAIN: 'avior-swiss.co',
  TAGS: { USERS: 'users', PARTNERS: 'partners' },
  // CAMPAIGN_ID: 'ClataMcB87y', // Referral campaign
  // PARTNERS_CHANNEL_ID: 'Hlb3elcYbXG', // Partners channel - for partner links
  // USERS_CHANNEL_ID: 'Hlb3eGj2e3T', // Users channel - for user links
};

const SMS_TYPE = {
  APPOINTMENT: 'cnst.sms_type.appointment', // sent immediately on presentation creation
  FIRST_REMINDER: 'cnst.sms_type.first_reminder', // scheduled send at 9AM the day before presentation (reminder)
  SECOND_REMINDER: 'cnst.sms_type.second_reminder', // scheduled send for 2h before presentation (reminder)
};

// twilio statuses
const SMS_STATUS = {
  canceled: 'cnst.sms_status.canceled',
  accepted: 'cnst.sms_status.accepted',
  planned: 'cnst.sms_status.planned',
  scheduled: 'cnst.sms_status.scheduled',
  queued: 'cnst.sms_status.queued',
  sent: 'cnst.sms_status.sent',
  failed: 'cnst.sms_status.failed',
  delivered: 'cnst.sms_status.delivered',
  undelivered: 'cnst.sms_status.undelivered',
};

const APP_LANGUAGES = ['en', 'de', 'ru', 'it', 'index', 'de+', 'ru+', 'it+'];

const APP_SETTING = {
  COMPANY_PHONE: 'COMPANY_PHONE',
  COMPANY_EMAIL: 'COMPANY_EMAIL',
  COMPANY_NAME: 'COMPANY_NAME',
  RECEIPT_ADDR_ADDRESS: 'RECEIPT_ADDR_ADDRESS',
  RECEIPT_COMPANY_NAME: 'RECEIPT_COMPANY_NAME',
  RECEIPT_ADDR_CITY: 'RECEIPT_ADDR_CITY',
  RECEIPT_PARTNER_NAME: 'RECEIPT_PARTNER_NAME',
  RECEIPT_LOGO_PATH: 'RECEIPT_LOGO_PATH',
  SHOP_SHORTLINK: 'SHOP_SHORTLINK',
  WEBSITE_LINK: 'WEBSITE_LINK',
  SMS_COMPANY_EMAIL: 'SMS_COMPANY_EMAIL',
  SMS_COMPANY_NAME: 'SMS_COMPANY_NAME',
  SMS_COMPANY_PHONE: 'SMS_COMPANY_PHONE',
};

const EVENT_TYPE = {
  PRESENTATION: null,
  SERVICE: null,
};

const PRESENTATION_STEPS = {
  OVERVIEW: 'pages.events.tabs.overview',
  ORDER: 'pages.events.tabs.order',
  USER_PROFILE: 'pages.events.tabs.user_profile',
  SUB_USER_PROFILE: 'pages.events.tabs.sub_user_profile',
  UPLOAD_CONTRACT: 'pages.events.tabs.upload_contract',
};

const BAD_QUALITY_REASON = {
  WAS_ABSENT: 'cnst.bad_quality_reason.was_absent',
  YOUNG: 'cnst.bad_quality_reason.young',
  OLD: 'cnst.bad_quality_reason.old',
  BAD_FINANCE: 'cnst.bad_quality_reason.bad_financial_status',
  NOT_WORKING: 'cnst.bad_quality_reason.not_working',
  DIRTY_HOUSE: 'cnst.bad_quality_reason.dirty_house',
  MY_REASON: 'cnst.bad_quality_reason.my_reason',
  ALREADY_HAS: 'cnst.bad_quality_reason.already_has_water_system',
};

const PRODUCT_TYPE = keyMirror({
  GIFT: null,
  MAIN: null,
  HULA: null,
  OTHER: null,
});

const CUSTOM_FILTER_TYPE = keyMirror({
  TEXT: null,
  ENUM: null,
  DATE: null,
  BOOL: null,
  NUMBER: null,
  CUSTOM: null,
});

const { TEXT, NUMBER, ENUM, DATE, BOOL, REF } = CUSTOM_FILTER_TYPE;

const USER_CUSTOM_FILTER_FIELD_TYPE = {
  email: TEXT,
  zipcode: TEXT,
  city: TEXT,
  address: TEXT,
  has_onboarding_sms: BOOL,
  updated_at: DATE,
  created_at: DATE,
  working_status: ENUM,
  persons_at_home: ENUM,
  number_of_calls: NUMBER,
  category: TEXT,
  company: TEXT,
  lead_source: REF,
  open_recalls: DATE,
};

const USER_CUSTOM_FILTER_FIELD_LABEL = {
  email: 'filters.custom_field.email',
  zipcode: 'filters.custom_field.zipcode',
  city: 'filters.custom_field.city',
  address: 'filters.custom_field.address',
  has_onboarding_sms: 'filters.custom_field.has_onboarding_sms',
  updated_at: 'filters.custom_field.updated_at',
  created_at: 'filters.custom_field.created_at',
  working_status: 'filters.custom_field.working_status',
  persons_at_home: 'filters.custom_field.persons_at_home',
  number_of_calls: 'filters.custom_field.number_of_calls',
  category: 'users_table.columns.category',
  company: 'users_table.columns.company',
  lead_source: 'filters.custom.lead_source',
  open_recalls: 'filters.custom.open_recalls',
};

const PRESENTATION_PRODUCT_STATUS = {
  SELL: 'cnst.presentation_product_status.sell',
  REC: 'cnst.presentation_product_status.rec',
  GIFT: 'cnst.presentation_product_status.gift',
};

const PAYMENT_METHOD = {
  BILL: 'cnst.payment_method.bill',
  CASH: 'cnst.payment_method.cash',
  CARD: 'cnst.payment_method.card',
  BYJUNO: 'cnst.payment_method.byjuno',
  AVIOR: 'cnst.payment_method.avior',
};

const PERSONS_AT_HOME = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  MORE: 'cnst.persons_at_home.more',
};

const BANK_CODE = keyMirror({
  UBS: null,
  AXION: null,
  PICTET: null,
});

const MAIN_PRODUCT = {
  EV_3000: 'bc3a3354-8e56-42d0-9ec8-527e603a2b84',
  EV_POSEIDON: 'aa5f252a-d861-47fb-8cfd-fcbb122b2ce8',
  EV_ATLANTIS: 'aceb606e-7cd5-4e71-a493-758466c26f18',
  MK4_STEAM: '05caa439-362b-4fae-8a5f-400b7062081f',
};

const DEFERRED_PAYMENT_LENGTH = ['1', '2', '3', '6', '12', '18', '24'];

// user eager exp used in call agent table
const CA_WP_USER_EAGER_EXP =
  // eslint-disable-next-line max-len
  '[sub_user, status, role, contact_history, user_info, lead_source.[location, lead_user], presentations.[created_by, updated_by], created_by, user_sms, user_poll, status_history.[status, created_by]]';

// call agent workplace tables
const CAWP_TABLE_NAME = {
  B2C: 'container.ca_wp.table_name.b2c',
  B2B: 'container.ca_wp.table_name.b2b',
  BUYERS: 'container.ca_wp.table_name.buyers',
  EMAIL_REMINDERS: 'container.ca_wp.table_name.email_reminders',
};

// initially, we expect from user recommendations, but still there need to be a fallback to money.
const PRESENTATION_PRODUCT_REPAYMENT_TYPE = keyMirror({
  RECOMMENDATION: null,
  MONEY: null,
});

const CAWP_TABLE_NAME_KEYS = keyMirror(CAWP_TABLE_NAME);

const IMPORT_TYPE = keyMirror({
  OCR: null,
  PERSPECTIVE: null,
  LEADS: null,
});

const PARTNER_STATUS = {
  BUYERS: { label: 'cnst.partner_statuses.buyers', hex: '#4CAF50' },
  WAIT_PRESENTATION: { label: 'cnst.partner_statuses.wait_presentation', hex: '#62CA31' },
  REGISTERED: { label: 'cnst.partner_statuses.registered', hex: '#2196F3' },
  WAIT_FOR_CALL: { label: 'cnst.partner_statuses.wait_for_call', hex: '#fde626' },
  NOT_INTERESTED: { label: 'cnst.partner_statuses.not_interested', hex: '#E53935' },
  // REMEET_PRESENTATION: 'cnst.partner_statuses.remeet_presentation',
  CANCELED_PRESENTATION: { label: 'cnst.partner_statuses.canceled_presentation', hex: '#B71C1C' },
  NOT_ANSWERED: { label: 'cnst.partner_statuses.not_answered', hex: '#FF9800' },
  DONT_BUY: { label: 'cnst.partner_statuses.dont_buy', hex: '#263238' },
  WRONG_DATA: { label: 'cnst.partner_statuses.wrong_data', hex: '#546E7A' },
  DECLINED_BY_ADMIN: { label: 'cnst.partner_statuses.declined_by_admin', hex: '#E55F35' },
};

const EMAIL_REMINDER_STAGE = {
  FIRST: 'cnst.email_reminder_stage.first',
  SECOND: 'cnst.email_reminder_stage.second',
  THIRD: 'cnst.email_reminder_stage.third',
  EXPIRED: 'cnst.email_reminder_stage.expired',
};

// Social Networks lead source
const SM_LOCATION_ID = '3a6c2bb6-06c7-42aa-8c60-a84c491360f3';

const ACCESS_CLIENTS = keyMirror({
  ALL: null,
  B2C: null,
  B2B: null,
  NONE: null,
});

const OCR_UPLOAD_TYPE = keyMirror({
  GDRIVE: null,
  LOCAL: null,
});

const OCR_PROCESSING_STATUS = keyMirror({
  INIT: null,
  GDRIVE: null,
  BUCKET: null,
  OCR: null,
});

const OCR_TERMINATION_STATUS = keyMirror({
  INITIATED: null,
  TERMINATED: null,
});

const OCR_REVIEW_STATUS = {
  NOT_REVIEWED: 'cnst.ocr_review_status.not_reviewed',
  REVIEWED: 'cnst.ocr_review_status.reviewed',
  ALL: 'cnst.ocr_review_status.all',
};

const OCR_DUPLICATION_STATUS = {
  WITHOUT_DUPLICATES: 'cnst.ocr_duplication_status.without_duplicates',
  DUPLICATES: 'cnst.ocr_duplication_status.duplicates',
  ALL: 'cnst.ocr_duplication_status.all',
};

const OCR_REVIEW_STATUS_KEYS = getKeyMirror(OCR_REVIEW_STATUS);
const OCR_DUPLICATION_STATUS_KEYS = getKeyMirror(OCR_DUPLICATION_STATUS);

const OMNICAS_STATUS = {
  ACCEPTED: 'cnst.omnicas_status.accepted',
  IN_EXAMINATION: 'cnst.omnicas_status.in_examination',
  REJECTED: 'cnst.omnicas_status.rejected',
};

module.exports = {
  OMNICAS_STATUS,
  OMNICAS_STATUS_KEYS: getKeyMirror(OMNICAS_STATUS),
  OCR_DUPLICATION_STATUS,
  OCR_DUPLICATION_STATUS_KEYS,
  OCR_REVIEW_STATUS,
  OCR_REVIEW_STATUS_KEYS,
  OCR_UPLOAD_TYPE,
  OCR_PROCESSING_STATUS,
  OCR_TERMINATION_STATUS,
  ACCESS_CLIENTS,
  SM_LOCATION_ID,
  COMMON_DATE_FORMAT: 'DD.MM.YYYY',
  COMMON_DATETIME_FORMAT: 'DD.MM.YYYY HH:mm',
  DB_DATETIME_FORMAT: 'YYYY-MM-DD H:mm:ss',
  STATUSES,
  PRESENTATION_PERIOD,
  ROLES_ID_MAP,
  PROM_GIFTS,
  INITIAL_USER_STATE,
  ROLE,
  ROLE_KEYS,
  DASHBOARDS_MAP,
  STATUS_CODES: getKeyMirror(STATUSES),
  RECOMMENDATION_STATUS,
  RECOMMENDATION_STATUS_KEYS: getKeyMirror(RECOMMENDATION_STATUS),
  GENDER,
  GENDER_KEYS: getKeyMirror(GENDER),
  RELATIONS,
  PET,
  PET_KEYS: getKeyMirror(PET),
  CARPET_KEYS: getKeyMirror(CARPET),
  CLEANING_TARGET_KEYS: getKeyMirror(CLEANING_TARGET),
  WORKING_STATUSES,
  PRESENTATION_QUALITY,
  DONT_SAW_REASON,
  DONT_SAW_REASON_KEYS: getKeyMirror(DONT_SAW_REASON),
  PRESENTATION_STATUS,
  PRESENTATION_STATUS_LABEL,
  DIDNT_BUY_REASON,
  DIDNT_BUY_REASON_KEYS,
  PROM_GIFTS_DISPLAY_NAMES: {
    [PROM_GIFTS.JASMINE_BLACK]: 'cnst.gift.jasmine_black',
    [PROM_GIFTS.JASMINE_WHITE]: 'cnst.gift.jasmine_white',
    [PROM_GIFTS.JASMINE_TITANIUM]: 'cnst.gift.jasmine_titanium',
    [PROM_GIFTS.JASMINE_RED]: 'cnst.gift.jasmine_red',
    [PROM_GIFTS.ZOE_WHITE]: 'cnst.gift.zoe_white',
    [PROM_GIFTS.ZOE_BLACK]: 'cnst.gift.zoe_black',
    [PROM_GIFTS.WASCHKUGEL]: 'cnst.gift.waschkugel',
    [PROM_GIFTS.LUCY_BLACK]: 'cnst.gift.lucy_black',
    [PROM_GIFTS.LUCY_WHITE]: 'cnst.gift.lucy_white',
  },
  DAY_PHASES: keyMirror({
    MORNING: null,
    DAY: null,
    NIGHT: null,
  }),
  CLEANING_SYSTEMS,
  CLEANING_SYSTEMS_KEYS: getKeyMirror(CLEANING_SYSTEMS),
  API_ERRORS: {
    NOT_ALLOWED: 'Not allowed.',
    UNKNOWN_USER_HASH: 'common.validations.unknown_user_hash',
    UNKNOWN_USER_ID: 'UNKNOWN_USER_ID',
    UNKNOWN_EMAIL: 'common.validations.unknown_email',
    WRONG_PASSWORD: 'common.validations.wrong_pwd',
    DUPLICATED_EMAIL: 'common.validations.duplicated_email',
    NO_ENTITIES_WERE_UPDATED: 'NO_ENTITIES_WERE_UPDATED',
    NO_ENTITIES_WERE_CREATED: 'NO_ENTITIES_WERE_CREATED',
    ONLY_UNCONFIRMED_CAN_BE_DELETED: 'ONLY_UNCONFIRMED_CAN_BE_DELETED',
    UNKNOWN_LOCATION_ID: 'UNKNOWN_LOCATION_ID',
    WRONG_RECOVERY_HASH: 'common.validations.wrong_recovery_hash',
    IP_BLOCKED: 'api_errors.ip_blocked',
  },
  VALIDATION_ERRORS: {
    PASSWORDS_DONT_MATCH: 'common.validations.pwd_dont_match',
    INVALID_URL: 'common.validations.invalid_url',
    FIELD_IS_REQUIRED: 'common.validations.field_is_required',
    INVALID_PHONE: 'common.validations.invalid_phone',
    INVALID_EMAIL: 'common.validations.invalid_email',
    UNAUTHORIZED: 'common.validations.unauthorized',
    FIELD_NAME_IS_REQUIRED: 'common.validations.field_name_required',
    ONE_OF_DATES_IS_REQUIRED: 'common.validations.one_of_dates_is_required',
  },
  TIME_TO_CALL: keyMirror({
    MORNING: null,
    AFTERNOON: null,
    EVENING: null,
    NIGHT: null,
  }),
  LOCATION_TYPES: keyMirror({
    EVENT: null,
    MALL: null,
  }),
  CONTACT_HISTORY_TYPES: keyMirror({
    CALL: null,
    AUTO_CALL: null,
    SMS: null,
    RECALL: null,
  }),
  CONTACT_HISTORY_FLOW_TYPE,
  PARTNER_PAYMENT_STATUS,
  PARTNER_PAYMENT_STATUS_KEYS: getKeyMirror(PARTNER_PAYMENT_STATUS),
  BITLY,
  APP_LANGUAGES,
  SMS_TYPE,
  SMS_TYPE_KEYS: getKeyMirror(SMS_TYPE),
  SMS_STATUS,
  SMS_STATUS_KEYS: getKeyMirror(SMS_STATUS),
  APP_SETTING,
  APP_SETTING_KEYS: getKeyMirror(APP_SETTING),
  EVENT_TYPE,
  EVENT_TYPE_KEYS: getKeyMirror(EVENT_TYPE),
  PRESENTATION_STEPS,
  PRESENTATION_STEPS_KEYS: getKeyMirror(PRESENTATION_STEPS),
  PERSONS_AT_HOME,
  PERSONS_AT_HOME_KEYS: getKeyMirror(PERSONS_AT_HOME),
  BAD_QUALITY_REASON,
  BAD_QUALITY_REASON_KEYS: getKeyMirror(BAD_QUALITY_REASON),
  PRODUCT_TYPE,
  PRESENTATION_PRODUCT_STATUS,
  PRESENTATION_PRODUCT_STATUS_KEYS: getKeyMirror(PRESENTATION_PRODUCT_STATUS),
  PAYMENT_METHOD,
  PAYMENT_METHOD_KEYS: getKeyMirror(PAYMENT_METHOD),
  BANK_CODE,
  MAIN_PRODUCT,
  MAIN_PRODUCT_KEYS: getKeyMirror(MAIN_PRODUCT),
  CUSTOM_FILTER_TYPE,
  USER_CUSTOM_FILTER_FIELD_TYPE,
  USER_CUSTOM_FILTER_FIELD_LABEL,
  DEFERRED_PAYMENT_LENGTH,
  CA_WP_USER_EAGER_EXP,
  CAWP_TABLE_NAME,
  CAWP_TABLE_NAME_KEYS,
  PRESENTATION_PRODUCT_REPAYMENT_TYPE,
  IMPORT_TYPE,
  PARTNER_STATUS_KEYS: getKeyMirror(PARTNER_STATUS),
  PARTNER_STATUS,
  EMAIL_REMINDER_STAGE,
  EMAIL_REMINDER_STAGE_KEYS: getKeyMirror(EMAIL_REMINDER_STAGE),
  PLANNER_DESTINATION_TYPE,
  ADDRESS_VALIDATION_COMPONENT_TYPE,
  ADDRESS_VALIDATION_COMPONENT_TYPE_KEYS: getKeyMirror(ADDRESS_VALIDATION_COMPONENT_TYPE),
};
