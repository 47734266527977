import { actionTypes } from '../actions/addressValidation';

const initialState = {
  isLoading: false,
  initialData: {
    user_id: null,
    locality: '',
    postal_code: '',
    route: '',
    street_number: '',
  },
  error: null,
  isEditing: false,
  validationVerdict: {},
  validationResult: {},
};

const addressValidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROCESS_ADDRESS_ASYNC_START: {
      return {
        ...state,
        error: null,
        initialData: action.data,
        isLoading: true,
      };
    }
    case actionTypes.PROCESS_ADDRESS_ASYNC_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case actionTypes.PROCESS_ADDRESS_ASYNC_SUCCESS: {
      const { validationResult, validationVerdict } = action;
      return {
        ...state,
        isLoading: false,
        error: null,
        validationResult,
        validationVerdict,
      };
    }

    default:
      return state;
  }
};

export default addressValidationReducer;
