import keyMirror from 'key-mirror';

import { CUSTOM_FILTER_TYPE } from '../../constants';
import { VALIDATION_ERRORS, ROLE_KEYS } from '../../constants';

const { FIELD_NAME_IS_REQUIRED, ONE_OF_DATES_IS_REQUIRED } = VALIDATION_ERRORS;

// export goes only to the reducer
export const actionTypes = keyMirror({
  GET_USERS_ASYNC_START: null,
  GET_USERS_ASYNC_SUCCESS: null,
  GET_USERS_ASYNC_ERROR: null,
  GET_SA_USERS_ASYNC_START: null,
  GET_SA_USERS_ASYNC_SUCCESS: null,
  GET_SA_USERS_ASYNC_ERROR: null,
  RESET_USERS: null,
  CHANGE_PRESET_FILTER: null,
  ADD_CUSTOM_FILTER: null,
  CHANGE_CUSTOM_FILTER: null,
  DELETE_CUSTOM_FILTER: null,
  ADD_CUSTOM_FILTER_ERRORS: null,
  UPDATE_TABLE_ROW: null,
  BLOCK_ACCOUNT: null,
  UNBLOCK_ACCOUNT: null,
  CHANGE_SORTED: null,
  CHANGE_PAGE: null,
  CHANGE_PAGINATION: null,
  GET_USERS_WITH_ACCOUNTS_ASYNC_SUCCESS: null,
  GET_USERS_WITH_ACCOUNTS_ASYNC_ERROR: null,
});

/** this is not an action creator, just divided part from getUsers function for better reading
 *
 * @param dispatch
 * @param getState
 * @returns {null|{Object}} - request filters
 */
const getFiltersFromStore = (dispatch, getState) => {
  const {
    users: {
      filters: {
        custom,
        preset: {
          statuses,
          presentationStatuses,
          search,
          isSearchSeparated,
          recall,
          recall_today,
          newcomers,
          hyla_users,
          recommendations,
          has_recommendations,
          has_remeet,
          sm_leads,
        },
        sorted,
      },
    },
    callAgentWorkplace: { currentTable },
  } = getState();

  // filters that will be applied by default
  const defaultFilters = { roles: [ROLE_KEYS.USER] };

  // validation for custom filters
  const validated = custom.map(({ name, value, type }) => {
    const field = name ? '' : FIELD_NAME_IS_REQUIRED;
    const valueError =
      type === CUSTOM_FILTER_TYPE.DATE
        ? !value || (!value.from && !value.to)
          ? ONE_OF_DATES_IS_REQUIRED
          : ''
        : '';
    return { name, value, type, errors: { field, value: valueError } };
  });

  const hasErrors = validated.reduce((acc, { errors: { field, value } }) => {
    if (acc) return acc;
    return !!(field || value);
  }, false);

  if (hasErrors) {
    dispatch({ type: actionTypes.ADD_CUSTOM_FILTER_ERRORS, custom: validated });
    return null;
  }

  const params = custom.reduce((acc, { name, value }) => {
    acc[name] = value;
    return acc;
  }, {});

  if (statuses.length) {
    params.statuses = statuses;
  }

  if (presentationStatuses.length) {
    params.presentationStatuses = presentationStatuses;
  }

  if (search) {
    params.search = search;
  }

  if (isSearchSeparated) {
    params.isSearchSeparated = isSearchSeparated;
  }

  if (sorted.length) {
    const [sortedColumn] = sorted;
    params.orderBy = sortedColumn.id;
    params.orderMethod = sortedColumn.desc ? 'desc' : 'asc';
  }

  // REFACTOR: consider changing store structure a bit, to make this parsing easier?
  params.presets = [
    recall && 'recall',
    hyla_users && 'hyla_users',
    newcomers && 'newcomers',
    recall_today && 'recall_today',
    recommendations && 'recommendations',
    has_recommendations && 'has_recommendations',
    has_remeet === '1' ? 'has_remeet' : has_remeet === '2' ? 'has_last_remeet' : false,
    sm_leads === '1' ? 'only_sm_leads' : sm_leads === '2' ? 'exclude_sm_leads' : false,
  ].filter(Boolean);

  return { ...defaultFilters, ...params, type: currentTable };
};

export default {
  /**
   * @param params {Object} - parameters for the request.
   * @param params.filters {Object} - filters, if not provided - will be taken from store.
   * In case of error during the
   * @param params.limit {number} - limit, if not provided - will be taken from store
   * @param params.offset {number} - offset, if not provided - will be taken from store
   */
  getUsers: params => (dispatch, getState, api) => {
    const {
      users: { offset, limit },
    } = getState();
    const providedLimit = params?.limit;
    const providedOffset = params?.offset;
    const filters = params?.filters || getFiltersFromStore(dispatch, getState);

    // in case of error during the filters parsing - won't call the request
    if (!filters) {
      return Promise.resolve();
    }
    const actualParams = {
      ...params,
      limit: typeof providedLimit === 'number' ? providedLimit : limit,
      offset: typeof providedOffset === 'number' ? providedOffset : offset,
      ...filters,
    };
    dispatch({ type: actionTypes.GET_USERS_ASYNC_START });
    return api.Users.getUsers(actualParams).then(
      payload => dispatch({ type: actionTypes.GET_USERS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_USERS_ASYNC_ERROR, error }),
    );
  },
  getUsersWithAccounts: params => (dispatch, getState, api) => {
    return api.Users.getUsersWithAccounts(params).then(
      payload => dispatch({ type: actionTypes.GET_USERS_WITH_ACCOUNTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_USERS_WITH_ACCOUNTS_ASYNC_ERROR, error }),
    );
  },
  getSalesAgentUsers: params => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_SA_USERS_ASYNC_START });
    return api.Users.getSalesAgentUsers(params).then(
      payload => dispatch({ type: actionTypes.GET_SA_USERS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_SA_USERS_ASYNC_ERROR, error }),
    );
  },
  resetUsers: () => dispatch => dispatch({ type: actionTypes.RESET_USERS }),
  changePresetFilter: filter => dispatch =>
    dispatch({ type: actionTypes.CHANGE_PRESET_FILTER, filter }),
  addCustomFilter: () => dispatch => dispatch({ type: actionTypes.ADD_CUSTOM_FILTER }),
  changeCustomFilter: filter => dispatch =>
    dispatch({ type: actionTypes.CHANGE_CUSTOM_FILTER, filter }),
  deleteCustomFilter: index => dispatch =>
    dispatch({ type: actionTypes.DELETE_CUSTOM_FILTER, index }),
  /**
   * @param {Object} data
   * @param {string} data.userId - user Id that will be updated
   * @param {Object} data.newRow - new row data
   */
  updateTableRow: data => dispatch => dispatch({ type: actionTypes.UPDATE_TABLE_ROW, data }),
  blockAccount: id => (dispatch, getState, api) =>
    api.Users.blockAccount(id).then(payload =>
      dispatch({ type: actionTypes.BLOCK_ACCOUNT, payload }),
    ),
  unblockAccount: id => (dispatch, getState, api) =>
    api.Users.unblockAccount(id).then(payload =>
      dispatch({ type: actionTypes.UNBLOCK_ACCOUNT, payload }),
    ),
  changeSorted: sorted => dispatch => dispatch({ type: actionTypes.CHANGE_SORTED, sorted }),
  changePage: pageIndex => dispatch => dispatch({ type: actionTypes.CHANGE_PAGE, pageIndex }),

  changePagination: payload => dispatch =>
    dispatch({ type: actionTypes.CHANGE_PAGINATION, payload }),
};
