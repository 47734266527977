import request from '../utils/request';

export default {
  Account: {
    getAccounts: () => request.get('/api/accounts'),
    updateAccount: (id, data) => request.put(`/api/account/${id}`, data),
  },

  Mailer: {
    sendSignupMail: id => request.get(`/api/send-signup-mail/${id}`),
    sendInnerSignupMail: id => request.get(`/api/send-inner-signup-mail/${id}`),
    activateLicenseSubmitEmail: id => request.get(`/api/activate-license-submit/${id}`),
    // sendRecReminderEmail: id => request.get(`/api/send-rec-reminder-email/${id}`),
    getEmailRemindersList: data => request.get(`/api/email-reminders-list`, data),
    activateLicenseRequestEmail: data => request.post(`/api/activate-license-request`, data),
    updateEmailReminders: params => request.post(`/api/update-email-reminders`, params),
  },

  User: {
    createSubUser: (id, data) => request.post(`/api/sub-user/${id}`, data),
    getUser: id => request.get(`/api/user/${id}`),
    editUser: (id, data) => request.put(`/api/user/${id}`, data),
    deleteUser: id => request.del(`/api/user/${id}`),
    deleteSubUser: id => request.del(`/api/sub-user/${id}`),
    createUser: data => request.post('/api/user', data),
    checkEmail: email => request.get(`/api/user-check-email/${email}`),
    getUserRecommendationsCount: id => request.get(`/api/user-rec-count/${id}`),
    updateUserAccessibility: data => request.post('/api/user-accessibility', data),
    getUserInfo: id => request.get(`/api/user-info/${id}`),
    addUserInfo: (id, data) => request.post(`/api/user-info/${id}`, data),
    deleteUserInfo: (userId, data) => request.del(`/api/user-info/${userId}`, data),
    getUserProducts: userId => request.get(`/api/user-products/${userId}`),
    setupRefLink: userId => request.get(`/api/setup-ref-link/${userId}`),
    getUserPrimaryAgents: userId => request.get(`/api/user-primary-agents/${userId}`),
  },

  Users: {
    getPlainUsers: params => request.get('/api/plain-users', params),
    getUsers: params => request.get('/api/users', params),
    getUsersWithAccounts: params => request.get('/api/users-with-accounts', params),
    getSalesAgentUsers: params => request.get('/api/sales-agent-users', params),
    blockAccount: id => request.get(`/api/block-account/${id}`),
    unblockAccount: id => request.get(`/api/unblock-account/${id}`),
  },

  Presentation: {
    getPresentation: id => request.get(`/api/presentation/${id}`),
    createPresentation: data => request.post('/api/presentation', data),
    updatePresentation: (id, data) => request.put(`/api/presentation/${id}`, data),
    finishPresentation: (id, data) => request.put(`/api/finish-presentation/${id}`, data),
    deletePresentation: id => request.del(`/api/presentation/${id}`),
  },

  PresentationProducts: {
    createPresentationProducts: data => request.post('/api/presentation-products', data),
    getPresentationProducts: id =>
      id
        ? request.get(`/api/presentation-products/${id}`)
        : request.get('/api/presentation-products'),
  },

  Presentations: {
    getPresentations: params => request.get('/api/presentations', params),
    getPresentationsCounters: params => request.get('/api/presentations-counts/', params),
  },

  Recommendations: {
    // id - referer user id, if not provided - it will be the id of current user
    getRecommendations: (id, params) =>
      id
        ? request.get(`/api/recommendations/${id}`, params)
        : request.get('/api/recommendations', params),

    // OBSOLETE ??
    // addPotentialRecommendations: (data, referrerId) =>
    //   referrerId
    //     ? request.post(`/api/add-potential-rec/${referrerId}`, data)
    //     : request.post('/api/add-potential-rec', data),
  },

  ContactHistory: {
    add: (userId, data) => request.post(`/api/contact-history/${userId}`, data),
    edit: (userId, data) => request.put(`/api/contact-history/${userId}`, data),
  },

  Auth: {
    login: data => request.post('/auth/login', data),
    signup: data => request.post('/auth/signup', data),
    recoveryEmail: data => request.post('/auth/recovery-email', data),
    resetPassword: data => request.post('/auth/reset-password', data),
    logout: () => request.get('/auth/logout'),
    getCurrentUser: () => request.get('/auth/current'),
    editCurrentUser: data => request.put('/auth/edit-current', data),
    loginBy: user_id => request.get(`/auth/login-by/${user_id}`),
  },

  Public: {
    createReferralAgent: data => request.post('/api/referral-agent', data),
    createReferralClient: data => request.post('/api/create-referral-client', data),
  },

  General: {
    getLocations: () => request.get('/api/locations'),
    getLocationsWithUsers: () => request.get('/api/locations-with-users'),
    getSalesAgents: () => request.get('/api/sales-agents'),
    getCallAgents: () => request.get('/api/call-agents'),
    addLocation: data => request.post('/api/location', data),
    updateLocation: (locationId, data) => request.put(`/api/location/${locationId}`, data),
    getStatuses: () => request.get('/api/statuses'),
    getAppSettings: () => request.get('/api/global-settings'),
    updateAppSettings: data => request.post('/api/global-settings', data),
    omnicas: data => request.post('/api/omnicas', data),
  },

  Products: {
    getProducts: params => request.get('/api/products', params),
  },

  Categories: {
    getCategories: () => request.get('/api/categories'),
  },

  Academy: {
    getSlides: () => request.get('/api/slides'),
    getVideos: () => request.get('/api/videos'),
    getAudios: () => request.get('/api/audios'),
    getDocs: () => request.get('/api/docs'),
  },

  CallAgentWorkplace: {
    getData: params => request.get(`/api/call-agent-wp/data`, params),
    addContactHistory: (userId, data, params) =>
      request.post(`/api/call-agent-wp/add-contact-history/${userId}`, data, params),
    editContactHistory: (userId, data, params) =>
      request.post(`/api/call-agent-wp/edit-contact-history/${userId}`, data, params),
    editPresentationProducts: (presentationId, data) =>
      request.put(`/api/call-agent-wp/edit-presentation-products/${presentationId}`, data),
    deletePresentationProduct: presentationProductId =>
      request.del(`/api/call-agent-wp/delete-presentation-product/${presentationProductId}`),
    addServiceEntry: (presentationId, data) =>
      request.post(`/api/call-agent-wp/add-service-entry/${presentationId}`, data),
    removeServiceEntry: serviceId =>
      request.del(`/api/call-agent-wp/remove-service-entry/${serviceId}`),
    updateServiceEntry: (serviceId, data) =>
      request.post(`/api/call-agent-wp/update-service-entry/${serviceId}`, data),
  },

  Statistic: {
    getSalesAgentData: params => request.get(`/api/stats/sales-agent`, params),
    getCallAgentData: params => request.get(`/api/stats/call-agent`, params),
    getDashboardData: params => request.get(`/api/stats/dashboard-statistic`, params),
    getPartnerData: params => request.get(`/api/stats/partner`, params),
    getLeadsData: params => request.get(`/api/stats/leads`, params),
  },

  Import: {
    prepareImport: params => request.get('/api/import/prepare-import', params),
    launchImport: (data, params) => request.post('/api/import/launch-import', data, params),
    uploadFromGDrive: data => request.post('/api/import/upload-from-gdrive', data),
    updateRecord: (data, params) => request.post('/api/import/update-record', data, params),
    poolOCRResult: () => request.get('/api/import/pool-ocr-result'),
    terminateRunningOCR: () => request.get('/api/import/terminate-running-ocr'),
    getOCRImports: () => request.get('/api/import/ocr-imports'),
    parseOCRImports: () => request.post('/api/import/ocr-imports'),
  },

  Partner: {
    getUsers: params => request.get('/api/partner-users', params),
    getPayments: params => request.get('/api/partner-payments', params),
    editPayments: data => request.post('/api/partner-payments', data),
  },

  Events: {
    getEventsUpcoming: params => request.get('/api/events-upcoming', params),
    getEventsHistory: params => request.get('/api/events-history', params),
  },

  Event: {
    getEvent: ({ eventType, id }) => request.get(`/api/event/${id}`, { eventType }),
    performService: (service_id, data) =>
      request.post(`/api/event/perform-service/${service_id}`, data),
  },

  Calendar: {
    createEvent: data => request.post(`/api/calendar-event`, data),
    updateEvent: data => request.put(`/api/calendar-event`, data),
    moveEvent: data => request.post(`/api/calendar-event-move`, data),
  },

  Planner: {
    getPreview: data => request.get(`/api/planner-preview`, data),
    getDetails: data => request.get(`/api/planner-details`, data),
    getBlockedDatesForMonth: data => request.get(`/api/planner-blocked-dates`, data),
  },

  AddressValidation: {
    processAddress: data => request.post(`/api/address-validation`, data),
  },
};
