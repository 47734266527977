import { actionTypes } from '../actions/snackbar';

const initialState = {
  isOpen: false,
  type: 'error', // one of ['error', 'info', 'success'],
  iData: null,
  message: '',
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_SNACKBAR:
      return {
        ...state,
        ...action.data,
        isOpen: true,
      };
    case actionTypes.CLOSE_SNACKBAR:
      return initialState;
    default:
      return state;
  }
};

export default snackbarReducer;
