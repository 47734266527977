import keyMirror from 'key-mirror';
import snackbarActions from './snackbar';

export const actionTypes = keyMirror({
  GET_DATA_ASYNC_START: null,
  GET_DATA_ASYNC_SUCCESS: null,
  GET_DATA_ASYNC_ERROR: null,
  GET_EXT_ROW_DATA: null,
  ADD_SERVICE_ENTRY_ASYNC_SUCCESS: null,
  ADD_SERVICE_ENTRY_ASYNC_ERROR: null,
  REMOVE_SERVICE_ENTRY_ASYNC_SUCCESS: null,
  REMOVE_SERVICE_ENTRY_ASYNC_ERROR: null,
  UPDATE_SERVICE_ENTRY_ASYNC_SUCCESS: null,
  UPDATE_SERVICE_ENTRY_ASYNC_ERROR: null,
  // underscored before merge with users table, to prevent collisions
  _CHANGE_PAGE: null,
  _CHANGE_PAGE_SIZE: null,
  _CHANGE_SORTED: null,
  _CHANGE_PRESET_FILTER: null,
  CHANGE_CURRENT_TABLE: null,
  UPDATE_ROW_DATA: null,
  REFRESH_TIMER_ASYNC_SUCCESS: null,
  SET_FILTERS_VISIBILITY: null,
  ADD_HISTORY_ASYNC_SUCCESS: null,
  ADD_HISTORY_ASYNC_ERROR: null,
  EDIT_HISTORY_ASYNC_SUCCESS: null,
  EDIT_HISTORY_ASYNC_ERROR: null,
  EDIT_ACCOUNT_ASYNC_SUCCESS: null,
  EDIT_ACCOUNT_ASYNC_ERROR: null,
  EDIT_PRESENTATION_PRODUCTS_ASYNC_START: null,
  EDIT_PRESENTATION_PRODUCTS_ASYNC_SUCCESS: null,
  EDIT_PRESENTATION_PRODUCTS_ASYNC_ERROR: null,
  DELETE_PRESENTATION_PRODUCT_ASYNC_START: null,
  DELETE_PRESENTATION_PRODUCT_ASYNC_SUCCESS: null,
  DELETE_PRESENTATION_PRODUCT_ASYNC_ERROR: null,
});

const prepareFilters = ({
  preset: { isSearchSeparated, search, finished, activated, invoice_sent },
  sorted,
}) => {
  const params = {};

  if (search) {
    params.search = search;
  }

  if (isSearchSeparated) {
    params.isSearchSeparated = isSearchSeparated;
  }

  if (sorted.length) {
    const [sortedColumn] = sorted;
    params.orderBy = sortedColumn.id;
    params.orderMethod = sortedColumn.desc ? 'desc' : 'asc';
  }

  params.presets = [
    finished && 'finished',
    activated && 'activated',
    invoice_sent === '1' ? 'invoice_sent' : invoice_sent === '2' ? 'invoice_not_sent' : false,
  ].filter(Boolean);

  return params;
};

export default {
  addServiceEntry: (presentationId, data) => (dispatch, getState, api) =>
    api.CallAgentWorkplace.addServiceEntry(presentationId, data).then(
      payload => dispatch({ type: actionTypes.ADD_SERVICE_ENTRY_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.ADD_SERVICE_ENTRY_ASYNC_ERROR, error }),
    ),

  removeServiceEntry: serviceId => (dispatch, getState, api) =>
    api.CallAgentWorkplace.removeServiceEntry(serviceId).then(
      payload => dispatch({ type: actionTypes.REMOVE_SERVICE_ENTRY_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.REMOVE_SERVICE_ENTRY_ASYNC_ERROR, error }),
    ),

  updateServiceEntry: (serviceId, data) => (dispatch, getState, api) =>
    api.CallAgentWorkplace.updateServiceEntry(serviceId, data).then(
      payload => dispatch({ type: actionTypes.UPDATE_SERVICE_ENTRY_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.UPDATE_SERVICE_ENTRY_ASYNC_ERROR, error }),
    ),

  /**
   * @param data - value of property that is updating
   * @param path - path represent a property that need to be updated, and should be a string
   * (it use lodash set, so path can be nested, F.E: 'user.account', etc)
   * @returns {object} - updated row
   */
  updateRowData: (data, path) => dispatch => {
    if (!path || typeof path !== 'string') {
      console.error('Path need to be a valid string');
      return null;
    }
    return dispatch({ type: actionTypes.UPDATE_ROW_DATA, data, path });
  },

  getData: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_DATA_ASYNC_START });
    const {
      callAgentWorkplace: { filters, offset, limit, currentTable },
    } = getState();
    const params = {
      offset,
      limit,
      tableName: currentTable,
      ...prepareFilters(filters),
    };
    return api.CallAgentWorkplace.getData(params).then(
      payload => dispatch({ type: actionTypes.GET_DATA_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_DATA_ASYNC_ERROR, error }),
    );
  },

  /**
   * Expanded row contains more detailed(extended) data, than we have in 'data' array,
   * so we need to get it with this additional fetch.
   * While additional data is loading, we still can render some data that we have.
   * @param rowId
   */
  getExtendedData: rowId => dispatch => dispatch({ type: actionTypes.GET_EXT_ROW_DATA, rowId }),
  addContactHistory: (userId, data) => (dispatch, getState, api) => {
    const {
      callAgentWorkplace: { currentTable },
    } = getState();
    const params = {
      tableName: currentTable,
    };
    return api.CallAgentWorkplace.addContactHistory(userId, data, params).then(
      payload => dispatch({ type: actionTypes.ADD_HISTORY_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.ADD_HISTORY_ASYNC_ERROR, error }),
    );
  },

  editAccount: (accountId, data) => (dispatch, getState, api) =>
    api.Account.updateAccount(accountId, data).then(
      payload => dispatch({ type: actionTypes.EDIT_ACCOUNT_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.EDIT_ACCOUNT_ASYNC_ERROR, error }),
    ),

  // editRecommendationInfo: (userId, data) => (dispatch, getState, api) =>
  //   api.CallAgentWorkplace.editRecommendationInfo(userId, data).then(
  //     payload => dispatch({ type: actionTypes.EDIT_REC_INFO_ASYNC_SUCCESS, payload }),
  //     error => dispatch({ type: actionTypes.EDIT_REC_INFO_ASYNC_ERROR, error }),
  //   ),

  editContactHistory: (userId, data) => (dispatch, getState, api) => {
    const {
      callAgentWorkplace: { currentTable },
    } = getState();
    const params = {
      tableName: currentTable,
    };
    return api.CallAgentWorkplace.editContactHistory(userId, data, params).then(
      payload => dispatch({ type: actionTypes.EDIT_HISTORY_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.EDIT_HISTORY_ASYNC_ERROR, error }),
    );
  },

  editPresentationProducts: (presentationId, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_START });
    return api.CallAgentWorkplace.editPresentationProducts(presentationId, data).then(
      payload => dispatch({ type: actionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.EDIT_PRESENTATION_PRODUCTS_ASYNC_ERROR, error }),
    );
  },

  deletePresentationProduct: presentationProductId => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.DELETE_PRESENTATION_PRODUCT_ASYNC_START });
    return api.CallAgentWorkplace.deletePresentationProduct(presentationProductId).then(
      payload => {
        dispatch(
          snackbarActions.openSnackbar(
            'cmp.snackbar.presentation_product_delete.success',
            'success',
          ),
        );
        return dispatch({ type: actionTypes.DELETE_PRESENTATION_PRODUCT_ASYNC_SUCCESS, payload });
      },
      error => {
        dispatch(
          snackbarActions.openSnackbar('cmp.snackbar.presentation_product_delete.error', 'error', {
            error: error.message || JSON.stringify(error),
          }),
        );
        return dispatch({ type: actionTypes.DELETE_PRESENTATION_PRODUCT_ASYNC_ERROR, error });
      },
    );
  },

  changeCurrentTable: tableName => dispatch =>
    dispatch({ type: actionTypes.CHANGE_CURRENT_TABLE, tableName }),

  setFiltersVisibility: visible => dispatch =>
    dispatch({ type: actionTypes.SET_FILTERS_VISIBILITY, visible }),

  changePresetFilter: filter => dispatch =>
    dispatch({ type: actionTypes._CHANGE_PRESET_FILTER, filter }),

  changePage: pageIndex => dispatch => dispatch({ type: actionTypes._CHANGE_PAGE, pageIndex }),

  changeSorted: sorted => dispatch => dispatch({ type: actionTypes._CHANGE_SORTED, sorted }),

  changePageSize: pageSize => dispatch =>
    dispatch({ type: actionTypes._CHANGE_PAGE_SIZE, pageSize }),
};
