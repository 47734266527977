import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  GET_LOCATIONS_ASYNC_START: null,
  GET_LOCATIONS_ASYNC_SUCCESS: null,
  GET_LOCATIONS_ASYNC_ERROR: null,
  GET_SALES_AGENTS_ASYNC_SUCCESS: null,
  GET_SALES_AGENTS_ASYNC_ERROR: null,
  GET_CALL_AGENTS_ASYNC_SUCCESS: null,
  GET_CALL_AGENTS_ASYNC_ERROR: null,
  GET_LOCATIONS_W_USERS_ASYNC_START: null,
  GET_LOCATIONS_W_USERS_ASYNC_SUCCESS: null,
  GET_LOCATIONS_W_USERS_ASYNC_ERROR: null,
  ADD_LOCATION_ASYNC_START: null,
  ADD_LOCATION_ASYNC_SUCCESS: null,
  ADD_LOCATION_ASYNC_ERROR: null,
  UPDATE_LOCATION_ASYNC_START: null,
  UPDATE_LOCATION_ASYNC_SUCCESS: null,
  UPDATE_LOCATION_ASYNC_ERROR: null,
  GET_STATUSES_ASYNC_START: null,
  GET_STATUSES_ASYNC_SUCCESS: null,
  GET_STATUSES_ASYNC_ERROR: null,
  GET_APP_SETTINGS_ASYNC_SUCCESS: null,
  GET_APP_SETTINGS_ASYNC_ERROR: null,
  UPDATE_APP_SETTINGS_ASYNC_SUCCESS: null,
  UPDATE_APP_SETTINGS_ASYNC_ERROR: null,
});

export default {
  getLocations: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_LOCATIONS_ASYNC_START });
    return api.General.getLocations().then(
      payload => dispatch({ type: actionTypes.GET_LOCATIONS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_LOCATIONS_ASYNC_ERROR, error }),
    );
  },

  getStatuses: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_STATUSES_ASYNC_START });
    return api.General.getStatuses().then(
      payload => dispatch({ type: actionTypes.GET_STATUSES_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_STATUSES_ASYNC_ERROR, error }),
    );
  },

  getLocationsWithUsers: () => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_START });
    return api.General.getLocationsWithUsers().then(
      payload => dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_LOCATIONS_W_USERS_ASYNC_ERROR, error }),
    );
  },

  addLocation: data => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.ADD_LOCATION_ASYNC_START });
    return api.General.addLocation(data).then(
      payload => dispatch({ type: actionTypes.ADD_LOCATION_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.ADD_LOCATION_ASYNC_ERROR, error }),
    );
  },

  updateLocation: (id, data) => (dispatch, getState, api) => {
    dispatch({ type: actionTypes.UPDATE_LOCATION_ASYNC_START });
    return api.General.updateLocation(id, data).then(
      payload => dispatch({ type: actionTypes.UPDATE_LOCATION_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.UPDATE_LOCATION_ASYNC_ERROR, error }),
    );
  },

  getSalesAgents: () => (dispatch, _, api) =>
    api.General.getSalesAgents().then(
      payload => dispatch({ type: actionTypes.GET_SALES_AGENTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_SALES_AGENTS_ASYNC_ERROR, error }),
    ),

  getCallAgents: () => (dispatch, _, api) =>
    api.General.getCallAgents().then(
      payload => dispatch({ type: actionTypes.GET_CALL_AGENTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_CALL_AGENTS_ASYNC_ERROR, error }),
    ),

  getAppSettings: () => (dispatch, getState, api) =>
    api.General.getAppSettings().then(
      payload => dispatch({ type: actionTypes.GET_APP_SETTINGS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_APP_SETTINGS_ASYNC_ERROR, error }),
    ),

  updateAppSettings: data => (dispatch, getState, api) =>
    api.General.updateAppSettings(data).then(
      payload => dispatch({ type: actionTypes.UPDATE_APP_SETTINGS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.UPDATE_APP_SETTINGS_ASYNC_ERROR, error }),
    ),
};
