import isEmpty from 'lodash/isEmpty';

import { actionTypes } from '../actions/partner';

import { replaceAt } from '../../utils';

const initialState = {
  filters: {
    sorted: [],
    search: '',
    status: '',
  },
  list: [],
  offset: 0,
  limit: 50,
  total: 0,
  isLoading: false,
  error: null,
};

const partnerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENTS_ASYNC_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_PAYMENTS_ASYNC_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case actionTypes.GET_PAYMENTS_ASYNC_SUCCESS: {
      const {
        payload: { data, offset, total, limit },
      } = action;
      return {
        ...state,
        offset,
        limit,
        total,
        list: data,
        error: null,
        isLoading: false,
      };
    }
    case actionTypes.CHANGE_FILTER: {
      const {
        payload: { name, value },
      } = action;
      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: value,
        },
      };
    }
    case actionTypes.CHANGE_SORTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          sorted: action.sorted,
        },
      };
    case actionTypes.CHANGE_PAGE: {
      return {
        ...state,
        offset: action.pageIndex * 50,
      };
    }
    // used for update limit || offset
    case actionTypes.CHANGE_PAGINATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default partnerReducer;
