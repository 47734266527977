import moment from 'moment';
import { flow } from 'lodash';

import { actionTypes } from '../actions/planner';
import { PLANNER_DESTINATION_TYPE } from '../../constants';

const addSpareTime = routes =>
  routes.map(({ destinations, ...rest }) => ({
    destinations: destinations.map((dest, idx, array) => {
      if (idx === 0) return { ...dest, spareTime: 0 };
      const { type, arrival_time, route } = dest;
      if (route.status === 'NOT_FOUND') {
        console.error('UNABLE TO FOUND ROUTE: ');
        console.log(dest);
      }

      const prevDest = array[idx - 1];
      const hasSpareTime =
        type !== PLANNER_DESTINATION_TYPE.HOME && prevDest?.type !== PLANNER_DESTINATION_TYPE.HOME;
      const spareTime = hasSpareTime
        ? moment(arrival_time).diff(prevDest?.arrival_time, 's') - 7200 - route.duration?.value
        : 0;

      return { ...dest, spareTime };
    }),
    ...rest,
  }));

const addTotalStats = routes =>
  routes.map(route => {
    const { destinations } = route;
    const firstEventTT = destinations[1].route.duration?.value;
    const homeTT = destinations[destinations.length - 1].route.duration?.value;
    const events = destinations.filter(({ type }) => type !== PLANNER_DESTINATION_TYPE.HOME);
    let totalEventsLength;
    if (events.length > 1) {
      const firstEventStart = moment(destinations[1].arrival_time);
      const lastEvent = destinations[destinations.length - 2];
      const lastEventFinish = lastEvent.end_time
        ? moment(lastEvent.end_time)
        : moment(lastEvent.arrival_time).add(2, 'h');
      totalEventsLength = lastEventFinish.diff(firstEventStart, 's');
    } else {
      totalEventsLength = 7200; // 1 demo - 2h
    }

    const stats = destinations.reduce(
      (acc, { route, spareTime }) => {
        if (spareTime < 0) acc.hasNegativeSpareTime = true;
        acc.spareTimeT += spareTime;

        if (route) {
          acc.driveDistanceT += route.distance?.value;
          acc.driveDurationT += route.duration?.value;
        }

        return acc;
      },
      {
        hasNegativeSpareTime: false,
        driveDistanceT: 0,
        driveDurationT: 0,
        spareTimeT: 0,
      },
    );

    return {
      ...route,
      ...stats,
      totalT: firstEventTT + homeTT + totalEventsLength,
      demoQ: destinations.filter(({ type }) => type === PLANNER_DESTINATION_TYPE.DEMO).length,
    };
  });

const AVERAGE_DRIVING_DISTANCE = 100;
// return the number between 0 and 1, based on distance. Lower distance -> higher value.
const getDistanceCoefficient = distanceInKm =>
  Math.pow(2, -distanceInKm / AVERAGE_DRIVING_DISTANCE);

const addPreferableCoefficient = state => routes => {
  const { primaryAgents } = state;
  return routes.map(route => {
    let coefficient = 0;
    const { agent, driveDistanceT, hasNegativeSpareTime, demoQ } = route;

    // is primary agent
    if (primaryAgents.find(({ id }) => id === agent.id)) coefficient += 1;

    if (hasNegativeSpareTime) coefficient -= 1;

    const distancePerDemoInKm = driveDistanceT / 1000 / demoQ;
    coefficient += getDistanceCoefficient(distancePerDemoInKm);

    return { ...route, coefficient };
  });
};

const calcBestPreviewRoute = (timestamp, state) => {
  const { rows, agents } = timestamp;
  const { primaryAgents } = state;

  return rows.reduce(
    (acc, curr, idx) => {
      const {
        elements: [item],
      } = curr;

      if (item.status !== 'OK') return acc;

      if (item.distance.value < acc.distance.value) {
        acc.distance.value = item.distance.value;
        acc.distance.text = item.distance.text;
        acc.duration.value = item.duration.value;
        acc.duration.text = item.duration.text;
      }

      // save the shortest distance for primary agent, if they exists
      const isPrimary = primaryAgents.find(({ id }) => id === agents[idx].id);
      if (isPrimary && item.distance.value < acc.primaryAgentDistance) {
        acc.primaryAgentDistance = item.distance.value;
      }

      return acc;
    },
    {
      distance: {
        text: '',
        value: Infinity,
      },
      duration: {
        text: '',
        value: Infinity,
      },
      primaryAgentDistance: Infinity,
    },
  );
};

const processPreviewForDate = (timestamps, state) => {
  let min_distance = Infinity;

  for (const key of Object.keys(timestamps)) {
    const timestamp = timestamps[key];

    timestamp.bestRoute = calcBestPreviewRoute(timestamp, state);

    if (timestamp.bestRoute.distance.value < min_distance) {
      min_distance = timestamp.bestRoute.distance.value;
    }

    timestamps[key] = timestamp;
  }

  return { timestamps, min_distance };
};

const filterPreviewForDate = (data, state, filters) => {
  const { primaryAgents } = state;
  const { timestamps } = data;
  const { availableForAgent, bestRoutes, tolerableRoutes } = filters || state.preview.filters;
  if (!availableForAgent && !bestRoutes && !tolerableRoutes) return data;

  const filteredTimestamps = {};
  const primaryAgentIds = primaryAgents.map(({ id }) => id);

  for (const key of Object.keys(timestamps)) {
    const timestamp = timestamps[key];
    const { agents } = timestamp;

    // filter by primary agent:
    if (availableForAgent) {
      const hasPrimary = agents.some(({ id }) => primaryAgentIds.indexOf(id) !== -1);
      if (!hasPrimary) continue;

      // filter by best agent dist:
      if (bestRoutes && timestamp.bestRoute.primaryAgentDistance > 50000) continue;

      // filter by tolerable agent dist:
      if (tolerableRoutes && timestamp.bestRoute.primaryAgentDistance > 100000) continue;
    }

    // filter by best dist
    if (bestRoutes && timestamp.bestRoute.distance.value > 50000) continue;

    // filter by tolerable dist
    if (tolerableRoutes && timestamp.bestRoute.distance.value > 100000) continue;

    filteredTimestamps[key] = timestamp;
  }

  return { timestamps: filteredTimestamps };
};

const filterPreview = (state, filters) => {
  const { raw } = state.preview;

  if (!filters.availableForAgent && !filters.bestRoutes && !filters.tolerableRoutes) return raw;

  const filtered = {};
  for (const date of Object.keys(raw)) {
    filtered[date] = filterPreviewForDate(raw[date], state, filters);
  }
  return filtered;
};

const processDetailsData = (routes, state) => {
  const processedRoutes = flow(
    addSpareTime,
    addTotalStats,
    addPreferableCoefficient(state),
  )(routes);

  return processedRoutes.sort((a, b) => {
    return b.coefficient - a.coefficient;
  });
};

export const initialState = {
  preview: {
    raw: {},
    filtered: {},
    filters: {
      bestRoutes: false,
      tolerableRoutes: true,
      availableForAgent: false,
    },
  },
  details: {
    timestamp: null,
    routes: [],
  },
  period: {
    startDate: moment().format(),
    endDate: moment().add(28, 'd').format(),
    startTime: moment()
      .set({ hour: 9, minute: 0, second: 0, millisecond: 0 })
      .add(moment().utcOffset(), 'm')
      .format(),
    endTime: moment()
      .set({ hour: 17, minute: 0, second: 0, millisecond: 0 })
      .add(moment().utcOffset(), 'm')
      .format(),
    separateDates: [moment().startOf('day')],
    enabledWeekdays: [1, 2, 3, 4, 5, 6],
    pinnedWeekday: null,
    activeCalendar: 'RANGE',
  },
  calendarDaysOff: {},
  agent: {},
  user: {},
  userDrafts: {},
  userErrors: {},
  isEditing: false,
  primaryAgents: [],
  step: 0,
  isLoading: false,
  isHeaderFixed: false,
  presentation_id: null,
  remeetEvent: null,
  error: null,
};

const plannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_PREVIEW: {
      return {
        ...state,
        preview: {
          raw: {},
          filtered: {},
          filters: state.preview.filters,
        },
      };
    }
    case actionTypes.CHANGE_USER_DRAFTS: {
      return {
        ...state,
        userDrafts: {
          ...state.userDrafts,
          ...action.data,
        },
      };
    }
    case actionTypes.CHANGE_USER_ERRORS: {
      return {
        ...state,
        userErrors: {
          ...state.userErrors,
          ...action.errors,
        },
      };
    }
    case actionTypes.CHANGE_STEP: {
      return {
        ...state,
        step: action.step,
      };
    }
    case actionTypes.TOGGLE_IS_EDITING: {
      return {
        ...state,
        isEditing: action.isEditing,
        ...(action.isEditing === false && {
          userDrafts: {},
          userErrors: {},
        }),
      };
    }
    case actionTypes.TOGGLE_FIXED_HEADER: {
      return {
        ...state,
        isHeaderFixed: !state.isHeaderFixed,
      };
    }
    case actionTypes.CHANGE_PERIOD: {
      return {
        ...state,
        period: {
          ...state.period,
          ...action.data,
        },
      };
    }
    case actionTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userErrors: {},
        userDrafts: {},
        user: action.payload,
      };
    }
    case actionTypes.GET_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: action.payload,
      };
    }
    case actionTypes.GET_BLOCKED_DATES_START: {
      return {
        ...state,
        isCalendarLoading: true,
      };
    }
    case actionTypes.UPDATE_USER_START:
    case actionTypes.CREATE_EVENT_START:
    case actionTypes.GET_PLANNER_DETAILS_START:
    case actionTypes.GET_PLANNER_PREVIEW_START:
    case actionTypes.CREATE_EVENT_PLANNER_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_BLOCKED_DATES_SUCCESS: {
      const { payload, month } = action;
      return {
        ...state,
        isCalendarLoading: false,
        calendarDaysOff: {
          ...state.calendarDaysOff,
          [month]: payload,
        },
      };
    }
    case actionTypes.TOGGLE_PREVIEW_FILTER: {
      const { filter } = action;
      const filters = { ...state.preview.filters, ...filter };
      return {
        ...state,
        preview: {
          ...state.preview,
          filters,
          filtered: filterPreview(state, filters),
        },
      };
    }
    case actionTypes.GET_PLANNER_PREVIEW_SUCCESS: {
      const { payload, date, purge } = action;

      // loading was stop manually by user - purge this calculations
      if (purge) {
        return {
          ...state,
          isLoading: false,
        };
      }

      const processedDate = processPreviewForDate(payload, state);

      return {
        ...state,
        isLoading: false,
        preview: {
          ...state.preview,
          raw: {
            ...state.preview.raw,
            [date]: processedDate,
          },
          filtered: {
            ...state.preview.filtered,
            [date]: filterPreviewForDate(processedDate, state),
          },
        },
      };
    }
    case actionTypes.GET_PLANNER_DETAILS_SUCCESS: {
      const { payload, timestamp } = action;
      return {
        ...state,
        isLoading: false,
        details: { timestamp, routes: processDetailsData(payload, state) },
      };
    }
    case actionTypes.GET_USER_PA_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        primaryAgents: payload,
      };
    }
    case actionTypes.CREATE_EVENT_PLANNER_SUCCESS: {
      const { presentation_id, service_id } = action;
      return {
        ...state,
        isLoading: false,
        presentation_id,
        service_id,
      };
    }
    case actionTypes.GET_USER_ERROR:
    case actionTypes.GET_EVENT_ERROR:
    case actionTypes.GET_USER_PA_ERROR:
    case actionTypes.UPDATE_USER_ERROR:
    case actionTypes.GET_PLANNER_DETAILS_ERROR:
    case actionTypes.CREATE_EVENT_PLANNER_ERROR:
    case actionTypes.GET_BLOCKED_DATES_ERROR:
    case actionTypes.GET_PLANNER_PREVIEW_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        isLoading: false,
        isCalendarLoading: false,
      };
    }
    case actionTypes.SET_SELECTED_AGENT: {
      const { agent } = action;
      return {
        ...state,
        agent,
      };
    }
    case actionTypes.GET_EVENT_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        remeetEvent: payload,
      };
    }
    default:
      return state;
  }
};

export default plannerReducer;
