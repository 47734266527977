import { actionTypes } from '../actions/events';
import { EVENT_TYPE_KEYS } from '../../constants';

const initialState = {
  filters: {
    sorted: [],
    search: '',
    status: '',
    agent_id: '',
  },
  currentTab: EVENT_TYPE_KEYS.PRESENTATION,
  list: [],
  offset: 0,
  limit: 50,
  total: 0,
  isLoading: false,
  error: null,
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EVENTS_UPCOMING_ASYNC_START:
    case actionTypes.GET_EVENTS_HISTORY_ASYNC_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_EVENTS_UPCOMING_ASYNC_ERROR:
    case actionTypes.GET_EVENTS_HISTORY_ASYNC_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case actionTypes.GET_EVENTS_UPCOMING_ASYNC_SUCCESS:
    case actionTypes.GET_EVENTS_HISTORY_ASYNC_SUCCESS: {
      const {
        payload: { data, offset, total, limit },
      } = action;
      return {
        ...state,
        offset: Number(offset),
        limit: Number(limit),
        total: Number(total),
        list: data,
        error: null,
        isLoading: false,
      };
    }
    case actionTypes.EVENTS_CHANGE_FILTER: {
      const {
        payload: { name, value },
      } = action;
      return {
        ...state,
        filters: {
          ...state.filters,
          [name]: value,
        },
      };
    }
    case actionTypes.EVENTS_CHANGE_SORTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          sorted: action.sorted,
        },
      };
    case actionTypes.EVENTS_CHANGE_PAGE: {
      return {
        ...state,
        offset: action.pageIndex * 50,
      };
    }
    // used for update limit || offset
    case actionTypes.EVENTS_CHANGE_PAGINATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case actionTypes.EVENTS_CHANGE_TAB: {
      return {
        ...state,
        offset: 0,
        currentTab: action.tab,
      };
    }
    default:
      return state;
  }
};

export default eventsReducer;
