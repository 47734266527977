import isEmpty from 'lodash/isEmpty';

import { actionTypes } from '../actions/users';
import { actionTypes as userActionTypes } from '../actions/user';
import { actionTypes as cawpActionTypes } from '../actions/callAgentWorkplace';

import { replaceAt } from '../../utils';

const DEFAULT_CUSTOM_FILTER = {
  name: '',
  type: null,
  value: '',
  errors: {
    field: '',
    value: '',
  },
};

const initialState = {
  filters: {
    visible: true,
    preset: {
      statuses: [],
      presentationStatuses: [],
      // preset buttons:
      recall: false,
      recall_today: false,
      hyla_users: false,
      newcomers: false,
      recommendations: false,
      sm_leads: '2', // exclude Social Media leads by the default
      has_recommendations: false,
      has_remeet: '0',
      // name + surname substr search
      search: '',
      isSearchSeparated: false,
    },
    sorted: [],
    /**
     * {Object[]} custom - array which represent custom filters:
     * custom[].name - {string} - field name
     * custom[].type - {string} - one of: TEXT, ENUM, DATE, BOOL
     * custom[].value - {string|boolean|object*} - field value
     * *object in case of DATE type: { from: 'ISOdate', to: 'ISOdate' }
     */
    custom: [],
  },
  list: [],
  offset: 0,
  limit: 100,
  total: 0,
  isLoading: false,
  listError: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_ASYNC_START:
    case actionTypes.GET_SA_USERS_ASYNC_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.GET_USERS_ASYNC_SUCCESS:
    case actionTypes.GET_USERS_WITH_ACCOUNTS_ASYNC_SUCCESS:
    case actionTypes.GET_SA_USERS_ASYNC_SUCCESS: {
      const {
        payload: { data, offset, total, limit },
      } = action;
      return {
        ...state,
        offset,
        limit,
        total,
        list: data,
        listError: null,
        isLoading: false,
      };
    }
    case actionTypes.GET_USERS_ASYNC_ERROR:
    case actionTypes.GET_USERS_WITH_ACCOUNTS_ASYNC_ERROR:
    case actionTypes.GET_SA_USERS_ASYNC_ERROR: {
      const { error } = action;
      return {
        ...state,
        listError: error,
        isLoading: false,
      };
    }
    case actionTypes.RESET_USERS:
      return initialState;
    case actionTypes.CHANGE_PRESET_FILTER: {
      const {
        filter: { name, value },
      } = action;
      return {
        ...state,
        filters: {
          ...state.filters,
          preset: {
            ...state.filters.preset,
            [name]: value,
          },
        },
      };
    }
    case actionTypes.ADD_CUSTOM_FILTER: {
      return {
        ...state,
        filters: {
          ...state.filters,
          custom: [...state.filters.custom, DEFAULT_CUSTOM_FILTER],
        },
      };
    }
    case actionTypes.CHANGE_CUSTOM_FILTER: {
      const { filter } = action;
      const {
        filters: { custom: oldData },
      } = state;
      const foundIndex = oldData.findIndex(({ name }) => name === filter.name);
      const index = foundIndex !== -1 ? foundIndex : oldData.length - 1;
      return {
        ...state,
        filters: {
          ...state.filters,
          custom: replaceAt(oldData, index, filter),
        },
      };
    }
    case actionTypes.ADD_CUSTOM_FILTER_ERRORS: {
      const { custom } = action;
      return {
        ...state,
        filters: {
          ...state.filters,
          custom,
        },
      };
    }
    case actionTypes.DELETE_CUSTOM_FILTER: {
      const { index } = action;
      const {
        filters: { custom: oldData },
      } = state;
      return {
        ...state,
        filters: {
          ...state.filters,
          custom: oldData.filter((filter, i) => i !== index),
        },
      };
    }
    case userActionTypes.DELETE_USER_INFO_SUCCESS: {
      const { list: oldList } = state;
      const { data } = action.payload;
      if (isEmpty(data)) return state;
      const index = oldList.findIndex(({ id }) => id === data.id);
      if (index === -1) {
        console.error('Unable to find row index');
        return state;
      }
      return {
        ...state,
        list: replaceAt(oldList, index, data),
      };
    }
    case actionTypes.UPDATE_TABLE_ROW: {
      const { list: oldList } = state;
      const { userId, newRow } = action.data;
      const index = oldList.findIndex(({ id }) => id === userId);
      if (index === -1) {
        console.error('Unable to find row index');
        return state;
      }
      return {
        ...state,
        list: replaceAt(oldList, index, newRow),
      };
    }
    case actionTypes.BLOCK_ACCOUNT:
    case actionTypes.UNBLOCK_ACCOUNT: {
      const { list: oldList } = state;
      const {
        data: { updatedId, is_active },
      } = action.payload;
      const index = oldList.findIndex(({ id }) => id === updatedId);
      const user = oldList.find(({ id }) => id === updatedId);
      return {
        ...state,
        list: replaceAt(oldList, index, { ...user, is_active }),
      };
    }
    case cawpActionTypes.CHANGE_CURRENT_TABLE: {
      return initialState;
    }
    case actionTypes.CHANGE_SORTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          sorted: action.sorted,
        },
      };
    case actionTypes.CHANGE_PAGE: {
      return {
        ...state,
        offset: action.pageIndex * 100,
      };
    }
    // used for update limit || offset
    case actionTypes.CHANGE_PAGINATION: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
