import { actionTypes } from '../actions/event';
import { actionTypes as eventsActionTypes } from '../actions/events';
import { PRESENTATION_STEPS_KEYS, PRESENTATION_STATUS } from '../../constants';
import { PRODUCT_TYPE } from '../../constants';

import { replaceAt } from '../../utils';

export const initialState = {
  data: {
    was_at_home: null,
    recall_time: null,
    order: null,
    user: {},
  },
  drafts: {
    was_at_home: null,
    recall_time: null,
    has_order: null,
    quality: null,
    bad_quality_reason: [],
    bad_quality_reason_desc: '',
    got_gift: null,
    gift: '',
    rej_gift_reason_desc: '',
    saw_presentation: null,
    dont_saw_reason: [],
    dont_saw_reason_desc: '',
    bought_product: null,
    dont_buy_reason: [],
    discount_rec_sum: null,
    discount_rec_count: null,
    payment_method: null,
    fast_pay: 0,
    contract_number: '',
    comments: '',
    order_products: [],
    user: {},
    sub_user: {},
    user_documents: [],
  },
  eventType: null,
  checkedTabs: [PRESENTATION_STEPS_KEYS.OVERVIEW],
  currentTab: PRESENTATION_STEPS_KEYS.OVERVIEW,
  isLoading: false,
  error: null,
};

const eventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EVENT_DATA_ASYNC_START:
      return {
        ...initialState,
        eventType: action.eventType,
        isLoading: true,
      };
    case actionTypes.GET_EVENT_DATA_ASYNC_ERROR:
    case actionTypes.PERFORM_EVENT_ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case actionTypes.PERFORM_EVENT_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EVENT_RESET_STATE:
    case actionTypes.PERFORM_EVENT_SUCCESS:
      return initialState;
    case actionTypes.GET_EVENT_DATA_ASYNC_SUCCESS: {
      const { payload } = action;
      const {
        user: { user_documents, sub_user },
      } = payload;

      const { status } = payload;

      if (status === PRESENTATION_STATUS.PLANNED) {
        return {
          ...state,
          drafts: {
            ...initialState.drafts,
            user_documents,
            user: payload.user,
            sub_user: sub_user || {
              address: payload.user.address,
              city: payload.user.city,
              zipcode: payload.user.zipcode,
            },
          },
          currentTab: PRESENTATION_STEPS_KEYS.OVERVIEW,
          data: payload,
          error: null,
          isLoading: false,
        };
      }

      const bought_product = payload.presentation_products?.find(
        ({ product }) => product.type === PRODUCT_TYPE.MAIN,
      );

      return {
        ...state,
        drafts: {
          ...payload,
          bad_quality_reason: payload.bad_quality_reason_desc
            ? payload.bad_quality_reason_desc.split(',')
            : [],
          dont_saw_reason: payload.dont_saw_reason ? payload.dont_saw_reason.split(',') : [],
          dont_buy_reason: payload.dont_buy_reason ? payload.dont_buy_reason.split(',') : [],
          bought_product: !!bought_product,
          got_gift: !!payload.gift,
          order_products: payload.presentation_products,
          user_documents,
          sub_user: sub_user || {},
        },
        currentTab: bought_product
          ? PRESENTATION_STEPS_KEYS.ORDER
          : PRESENTATION_STEPS_KEYS.OVERVIEW,
        data: payload,
        error: null,
        isLoading: false,
      };
    }
    case actionTypes.EVENT_CHANGE_TAB: {
      const { tab } = action;
      const { checkedTabs } = state;
      return {
        ...state,
        checkedTabs: checkedTabs.includes(tab) ? checkedTabs : [...checkedTabs, tab],
        currentTab: tab,
      };
    }
    case eventsActionTypes.EVENTS_CHANGE_TAB: {
      return {
        ...initialState,
      };
    }
    case actionTypes.EVENT_CHANGE_DRAFTS: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          ...action.data,
        },
      };
    }
    case actionTypes.EVENT_ADD_ORDER_PRODUCT: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          order_products: [...state.drafts.order_products, action.product],
        },
      };
    }
    case actionTypes.EVENT_REMOVE_ORDER_PRODUCT: {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          order_products: state.drafts.order_products.filter(
            ({ id }) => id !== action.order_product_id,
          ),
        },
      };
    }
    case actionTypes.EVENT_CHANGE_ORDER_PRODUCT: {
      const { order_products: oldList } = state.drafts;
      const { order_product_id, data } = action;
      const index = oldList.findIndex(({ id }) => id === order_product_id);
      const updatedData = { ...oldList[index], ...data };
      return {
        ...state,
        drafts: {
          ...state.drafts,
          order_products: replaceAt(oldList, index, updatedData),
        },
      };
    }
    default:
      return state;
  }
};

export default eventsReducer;
