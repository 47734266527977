const routes = require('next-routes');

/**
 * IMPORTANT: names of query params are important, and must be one of the given in Header component
 *  because we are using them for navigation.
 */

module.exports = routes()
  /** ********* *
   * DASHBOARDS *
   * ********** */
  .add({
    name: 'admin-dashboard',
    pattern: '/admin-dashboard',
    page: '/admin-dashboard',
  })
  .add({
    name: 'user-dashboard',
    pattern: '/user-dashboard',
    page: '/user-dashboard',
  })
  .add({
    name: 'pa-dashboard',
    pattern: '/pa-dashboard',
    page: '/pa-dashboard',
  })
  .add({
    name: 'sa-dashboard',
    pattern: '/sa-dashboard',
    page: '/sa-dashboard',
  })
  .add({
    name: 'ca-dashboard',
    pattern: '/ca-dashboard',
    page: '/ca-dashboard',
  })
  .add({
    name: 'partner-dashboard',
    pattern: '/partner-dashboard',
    page: '/partner-dashboard',
  })
  .add({
    name: 'om-dashboard',
    pattern: '/om-dashboard',
    page: '/om-dashboard',
  })

  /** ******************** *
   * maps for SHARED pages *
   * ********************* */
  // SEARCH
  .add({
    name: 'admin-dashboard-search',
    pattern: '/admin-dashboard/search',
    page: '/search',
  })
  .add({
    name: 'pa-dashboard-search',
    pattern: '/pa-dashboard/search',
    page: '/search',
  })
  .add({
    name: 'sa-dashboard-search',
    pattern: '/sa-dashboard/search',
    page: '/search',
  })
  .add({
    name: 'ca-dashboard-search',
    pattern: '/ca-dashboard/search',
    page: '/search',
  })

  // PLANNER
  .add({
    name: 'admin-planner',
    pattern: '/admin-dashboard/planner/:userId',
    page: '/planner',
  })
  .add({
    name: 'ca-planner',
    pattern: '/ca-dashboard/planner/:userId',
    page: '/planner',
  })
  .add({
    name: 'sa-planner',
    pattern: '/sa-dashboard/planner/:userId',
    page: '/planner',
  })

  // EDIT-PROFILE(SELF)
  .add({
    name: 'edit-admin-profile',
    pattern: '/admin-dashboard/edit-profile',
    page: '/edit-profile',
  })
  .add({
    name: 'edit-pa-profile',
    pattern: '/pa-dashboard/edit-profile',
    page: '/edit-profile',
  })
  .add({
    name: 'edit-ca-profile',
    pattern: '/ca-dashboard/edit-profile',
    page: '/edit-profile',
  })
  .add({
    name: 'edit-sa-profile',
    pattern: '/sa-dashboard/edit-profile',
    page: '/edit-profile',
  })
  .add({
    name: 'edit-user-profile',
    pattern: '/user-dashboard/edit-profile',
    page: '/edit-profile',
  })
  .add({
    name: 'edit-partner-profile',
    pattern: '/partner-dashboard/edit-profile',
    page: '/edit-profile',
  })
  .add({
    name: 'edit-om-profile',
    pattern: '/om-dashboard/edit-profile',
    page: '/edit-profile',
  })

  // CREATE ACCOUNT:
  .add({
    name: 'admin-create-account',
    pattern: '/admin-dashboard/create-account',
    page: '/create-account',
  })
  .add({
    name: 'sa-create-account',
    pattern: '/sa-dashboard/create-account',
    page: '/create-account',
  })
  .add({
    name: 'pa-create-account',
    pattern: '/pa-dashboard/create-account',
    page: '/create-account',
  })
  .add({
    name: 'ca-create-account',
    pattern: '/ca-dashboard/create-account',
    page: '/create-account',
  })
  .add({
    name: 'partner-create-account',
    pattern: '/partner-dashboard/create-account',
    page: '/partner-dashboard/create-account',
  })

  // CREATE USER:
  .add({
    name: 'admin-create-user-b2b',
    pattern: '/admin-dashboard/create-account/create-user-b2b',
    page: '/create-user-b2b',
  })
  .add({
    name: 'admin-create-user-b2c',
    pattern: '/admin-dashboard/create-account/create-user-b2c',
    page: '/create-user-b2c',
  })
  .add({
    name: 'sa-create-user-b2b',
    pattern: '/sa-dashboard/create-account/create-user-b2b',
    page: '/create-user-b2b',
  })
  .add({
    name: 'sa-create-user-b2c',
    pattern: '/sa-dashboard/create-account/create-user-b2c',
    page: '/create-user-b2c',
  })
  .add({
    name: 'pa-create-user-b2b',
    pattern: '/pa-dashboard/create-account/create-user-b2b',
    page: '/create-user-b2b',
  })
  .add({
    name: 'pa-create-user-b2c',
    pattern: '/pa-dashboard/create-account/create-user-b2c',
    page: '/create-user-b2c',
  })
  .add({
    name: 'ca-create-user-b2b',
    pattern: '/ca-dashboard/create-account/create-user-b2b',
    page: '/create-user-b2b',
  })
  .add({
    name: 'ca-create-user-b2c',
    pattern: '/ca-dashboard/create-account/create-user-b2c',
    page: '/create-user-b2c',
  })
  .add({
    name: 'partner-create-client',
    pattern: '/partner-dashboard/create-account/create-client',
    page: '/partner-dashboard/create-client',
  })
  .add({
    name: 'partner-zoe-flow',
    pattern: '/partner-zoe-flow/:leadHash',
    page: '/zoe-flow',
  })

  // CREATE AGENT:
  .add({
    name: 'sa-create-agent',
    pattern: '/sa-dashboard/create-account/create-agent',
    page: '/create-agent',
  })
  .add({
    name: 'admin-create-agent',
    pattern: '/admin-dashboard/create-account/create-agent',
    page: '/create-agent',
  })

  // SHOW USER:
  .add({
    name: 'admin-show-user',
    pattern: '/admin-dashboard/show-user/:userId',
    page: '/show-user',
  })
  .add({
    name: 'sa-show-user',
    pattern: '/sa-dashboard/show-user/:userId',
    page: '/show-user',
  })
  .add({
    name: 'ca-show-user',
    pattern: '/ca-dashboard/show-user/:userId',
    page: '/show-user',
  })
  .add({
    name: 'pa-show-user',
    pattern: '/pa-dashboard/show-user/:userId',
    page: '/show-user',
  })
  .add({
    name: 'partner-show-user',
    pattern: '/partner-dashboard/show-user/:userId',
    page: '/show-user',
  })

  // STATISTICS:
  .add({
    name: 'admin-statistics',
    pattern: '/admin-dashboard/statistics',
    page: '/statistics',
  })
  .add({
    name: 'ca-statistics',
    pattern: '/ca-dashboard/statistics',
    page: '/statistics',
  })
  .add({
    name: 'sa-statistics',
    pattern: '/sa-dashboard/statistics',
    page: '/statistics',
  })
  .add({
    name: 'pa-statistics',
    pattern: '/pa-dashboard/statistics',
    page: '/statistics',
  })

  // EDIT USER:
  .add({
    name: 'admin-edit-user',
    pattern: '/admin-dashboard/show-user/:userId/edit-user/:editUserId',
    page: '/edit-user',
  })
  .add({
    name: 'sa-edit-user',
    pattern: '/sa-dashboard/show-user/:userId/edit-user/:editUserId',
    page: '/edit-user',
  })
  .add({
    name: 'ca-edit-user',
    pattern: '/ca-dashboard/show-user/:userId/edit-user/:editUserId',
    page: '/edit-user',
  })

  // SHOW-RECOMMENDATIONS:
  .add({
    name: 'admin-show-user-recommendations',
    pattern: '/admin-dashboard/show-user/:userId/recommendations',
    page: '/user-recommendations',
  })
  .add({
    name: 'sa-show-user-recommendations',
    pattern: '/sa-dashboard/show-user/:userId/recommendations',
    page: '/user-recommendations',
  })
  .add({
    name: 'ca-show-user-recommendations',
    pattern: '/ca-dashboard/show-user/:userId/recommendations',
    page: '/user-recommendations',
  })

  // EDIT-RECOMMENDATIONS:
  .add({
    name: 'user-edit-recommendation',
    pattern: '/user-dashboard/edit-recommendation/:editRecId',
    page: '/user-dashboard/edit-recommendation',
  })
  .add({
    name: 'admin-edit-recommendation',
    pattern: '/admin-dashboard/show-user/:userId/recommendations/edit-rec/:editRecId',
    page: '/edit-recommendation',
  })
  .add({
    name: 'sa-edit-recommendation',
    pattern: '/sa-dashboard/show-user/:userId/recommendations/edit-rec/:editRecId',
    page: '/edit-recommendation',
  })
  .add({
    name: 'ca-edit-recommendation',
    pattern: '/ca-dashboard/show-user/:userId/recommendations/edit-rec/:editRecId',
    page: '/edit-recommendation',
  })

  // ADD-RECOMMENDATIONS:
  .add({
    name: 'user-create-recommendation',
    pattern: '/user-dashboard/create-recommendation',
    page: '/user-dashboard/create-recommendation',
  })
  .add({
    name: 'user-recommendations',
    pattern: '/user-dashboard/recommendations',
    page: '/user-dashboard/recommendations',
  })
  .add({
    name: 'user-products',
    pattern: '/user-dashboard/user-products',
    page: '/user-dashboard/products',
  })
  .add({
    name: 'admin-add-recommendation',
    pattern: '/admin-dashboard/show-user/:userId/recommendations/add-rec',
    page: '/add-recommendation',
  })
  .add({
    name: 'sa-add-recommendation',
    pattern: '/sa-dashboard/show-user/:userId/recommendations/add-rec',
    page: '/add-recommendation',
  })
  .add({
    name: 'ca-add-recommendation',
    pattern: '/ca-dashboard/show-user/:userId/recommendations/add-rec',
    page: '/add-recommendation',
  })

  // ACADEMY:
  .add({
    name: 'admin-academy',
    pattern: '/admin-dashboard/academy',
    page: '/academy',
  })

  .add({
    name: 'sa-academy',
    pattern: '/sa-dashboard/academy',
    page: '/academy',
  })

  .add({
    name: 'ca-academy',
    pattern: '/ca-dashboard/academy',
    page: '/academy',
  })

  .add({
    name: 'pa-academy',
    pattern: '/pa-dashboard/academy',
    page: '/academy',
  })

  // EVENTS-HISTORY (SA + ADMIN):
  .add({
    name: 'sa-events-history',
    pattern: '/sa-dashboard/events-history',
    page: '/events-history',
  })
  .add({
    name: 'admin-events-history',
    pattern: '/admin-dashboard/events-history',
    page: '/events-history',
  })

  // EVENTS-UPCOMING (SA + ADMIN):
  .add({
    name: 'sa-events-upcoming',
    pattern: '/sa-dashboard/events-upcoming',
    page: '/events-upcoming',
  })
  .add({
    name: 'admin-events-upcoming',
    pattern: '/admin-dashboard/events-upcoming',
    page: '/events-upcoming',
  })

  // VIEW-PRESENTATION (SA + ADMIN):
  .add({
    name: 'admin-view-presentation',
    pattern: '/admin-dashboard/events-history/view-presentation/:presentationId',
    page: '/view-presentation',
  })
  .add({
    name: 'sa-view-presentation',
    pattern: '/sa-dashboard/events-history/view-presentation/:presentationId',
    page: '/view-presentation',
  })

  // VIEW-SERVICE (SA + ADMIN):
  .add({
    name: 'admin-view-service',
    pattern: '/admin-dashboard/events-history/view-service/:serviceId',
    page: '/view-service',
  })
  .add({
    name: 'sa-view-service',
    pattern: '/sa-dashboard/events-history/view-service/:serviceId',
    page: '/view-service',
  })

  // VIEW-USER-PRESENTATION (SA + ADMIN):
  .add({
    name: 'admin-view-user-presentation',
    pattern: '/admin-dashboard/show-user/:userId/last-presentation/:presentationId',
    page: '/view-presentation',
  })
  .add({
    name: 'sa-view-user-presentation',
    pattern: '/sa-dashboard/show-user/:userId/last-presentation/:presentationId',
    page: '/view-presentation',
  })

  // PERFORM PRESENTATION (SA + ADMIN):
  .add({
    name: 'sa-perform-presentation',
    pattern: '/sa-dashboard/events-upcoming/perform-presentation/:presentationId',
    page: '/perform-presentation',
  })
  .add({
    name: 'admin-perform-presentation',
    pattern: '/admin-dashboard/events-upcoming/perform-presentation/:presentationId',
    page: '/perform-presentation',
  })

  // PERFORM SERVICE (SA + ADMIN):
  .add({
    name: 'sa-perform-service',
    pattern: '/sa-dashboard/events-upcoming/perform-service/:serviceId',
    page: '/perform-service',
  })
  .add({
    name: 'admin-perform-service',
    pattern: '/admin-dashboard/events-upcoming/perform-service/:serviceId',
    page: '/perform-service',
  })

  // Total statistics
  .add({
    name: 'admin-total-statistics',
    pattern: '/admin-dashboard/total-statistics',
    page: '/total-statistics',
  })
  .add({
    name: 'sa-total-statistics',
    pattern: '/sa-dashboard/total-statistics',
    page: '/total-statistics',
  })
  .add({
    name: 'ca-total-statistics',
    pattern: '/ca-dashboard/total-statistics',
    page: '/total-statistics',
  })

  // Warehouse
  .add({
    name: 'admin-warehouse',
    pattern: '/admin-dashboard/warehouse',
    page: '/warehouse',
  })
  .add({
    name: 'sa-warehouse',
    pattern: '/sa-dashboard/warehouse',
    page: '/warehouse',
  })

  // IMPORT
  .add({
    name: 'admin-import',
    pattern: '/admin-dashboard/import',
    page: '/import',
  })
  .add({
    name: 'om-import',
    pattern: '/om-dashboard/import',
    page: '/import',
  })

  // OMNICAS
  .add({
    name: 'admin-omnicas',
    pattern: '/admin-dashboard/omnicas',
    page: '/omnicas',
  })
  .add({
    name: 'sa-omnicas',
    pattern: '/sa-dashboard/omnicas',
    page: '/omnicas',
  })

  /** ***************************** *
   * maps for UNIQ PAGES (per role) *
   * ****************************** */
  // ADMIN uniq pages
  .add({
    name: 'locations',
    pattern: '/admin-dashboard/locations',
    page: '/admin-dashboard/locations',
  })
  .add({
    name: 'admin-create-admin',
    pattern: '/admin-dashboard/create-account/create-admin',
    page: '/admin-dashboard/create-admin',
  })
  .add({
    name: 'accounts-settings',
    pattern: '/admin-dashboard/accounts-settings',
    page: '/admin-dashboard/accounts-settings',
  })
  .add({
    name: 'admin-edit-account',
    pattern: '/admin-dashboard/accounts-settings/edit-account/:accountId',
    page: '/admin-dashboard/edit-account',
  })
  .add({
    name: 'admin-payments',
    pattern: '/admin-dashboard/admin-payments',
    page: '/admin-dashboard/admin-payments',
  })
  .add({
    name: 'receipt',
    pattern: '/admin-dashboard/receipt/:presentationId',
    page: '/admin-dashboard/receipt',
  })
  .add({
    name: 'global-settings',
    pattern: '/admin-dashboard/global-settings',
    page: '/admin-dashboard/global-settings',
  })

  // PARTNER uniq pages
  .add({
    name: 'partner-users',
    pattern: '/partner-dashboard/partner-users',
    page: '/partner-dashboard/partner-users',
  })
  .add({
    name: 'partner-payments',
    pattern: '/partner-dashboard/partner-payments',
    page: '/partner-dashboard/partner-payments',
  })

  // SA uniq pages

  // public
  .add({
    name: 'zoe-flow',
    pattern: '/zoe-flow/:leadHash',
    page: '/zoe-flow',
  });
