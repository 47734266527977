import { actionTypes } from '../actions/academy';

const initialState = {
  slides: {
    isLoading: false,
    data: [],
  },
  videos: {
    isLoading: false,
    data: [],
  },
  audios: {
    isLoading: false,
    data: [],
  },
  docs: {
    isLoading: false,
    data: [],
  },
};

const academyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SLIDES_ASYNC_START:
      return {
        ...state,
        slides: {
          ...state.slides,
          isLoading: true,
        },
      };
    case actionTypes.GET_SLIDES_ASYNC_SUCCESS:
      return {
        ...state,
        slides: {
          ...state.videos,
          isLoading: false,
          data: action.payload,
        },
      };
    case actionTypes.GET_VIDEOS_ASYNC_START:
      return {
        ...state,
        videos: {
          ...state.videos,
          isLoading: true,
        },
      };
    case actionTypes.GET_VIDEOS_ASYNC_SUCCESS:
      return {
        ...state,
        videos: {
          ...state.videos,
          isLoading: false,
          data: action.payload,
        },
      };
    case actionTypes.GET_AUDIOS_ASYNC_START:
      return {
        ...state,
        audios: {
          ...state.audios,
          isLoading: true,
        },
      };
    case actionTypes.GET_AUDIOS_ASYNC_SUCCESS:
      return {
        ...state,
        audios: {
          ...state.audios,
          isLoading: false,
          data: action.payload,
        },
      };
    case actionTypes.GET_DOCS_ASYNC_START:
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: true,
        },
      };
    case actionTypes.GET_DOCS_ASYNC_SUCCESS:
      return {
        ...state,
        docs: {
          ...state.docs,
          isLoading: false,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default academyReducer;
