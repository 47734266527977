import { actionTypes } from '../actions/general';

const initialState = {
  statuses: {
    list: [],
    isLoading: false,
  },
  locations: {
    list: [],
    isLoading: false,
  },
  salesAgents: [],
  callAgents: [],
  activeCallAgents: [],
  activeSalesAgents: [],
  appSettings: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STATUSES_ASYNC_START:
      return {
        ...state,
        statuses: {
          list: [],
          isLoading: true,
        },
      };
    case actionTypes.GET_STATUSES_ASYNC_SUCCESS:
      return {
        ...state,
        statuses: {
          list: action.payload,
          isLoading: false,
        },
      };
    case actionTypes.GET_LOCATIONS_ASYNC_START:
    case actionTypes.GET_LOCATIONS_W_USERS_ASYNC_START:
      return {
        ...state,
        locations: {
          list: [],
          isLoading: true,
        },
      };
    case actionTypes.GET_LOCATIONS_ASYNC_SUCCESS:
    case actionTypes.GET_LOCATIONS_W_USERS_ASYNC_SUCCESS:
      return {
        ...state,
        locations: {
          list: action.payload,
          isLoading: false,
        },
      };
    case actionTypes.GET_LOCATIONS_ASYNC_ERROR:
    case actionTypes.GET_LOCATIONS_W_USERS_ASYNC_ERROR:
      return {
        ...state,
        locations: {
          list: [],
          isLoading: false,
        },
      };
    case actionTypes.ADD_LOCATION_ASYNC_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        locations: {
          list: [{ ...data, users: 0 }, ...state.locations.list],
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_SALES_AGENTS_ASYNC_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        salesAgents: data,
        activeSalesAgents: data
          .filter(agent => !!agent?.is_active)
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    }
    case actionTypes.GET_CALL_AGENTS_ASYNC_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        callAgents: data,
        activeCallAgents: data
          .filter(agent => !!agent?.is_active)
          .sort((a, b) => a.name.localeCompare(b.name)),
      };
    }
    case actionTypes.UPDATE_LOCATION_ASYNC_SUCCESS: {
      // we need to merge 'users' count from old row with updated one
      const { data: updated } = action.payload;
      const old = state.locations.list.find(({ id }) => id === updated.id);
      const merged = { ...old, ...updated };
      return {
        ...state,
        locations: {
          list: [merged, ...state.locations.list.filter(({ id }) => id !== merged.id)],
          isLoading: false,
        },
      };
    }
    case actionTypes.GET_APP_SETTINGS_ASYNC_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        appSettings: payload,
      };
    }
    case actionTypes.UPDATE_APP_SETTINGS_ASYNC_SUCCESS: {
      const {
        payload: { updatedSettings },
      } = action;
      return {
        ...state,
        appSettings: {
          ...state.appSettings,
          ...updatedSettings,
        },
      };
    }
    default:
      return state;
  }
};

export default authReducer;
