import keyMirror from 'key-mirror';

export const actionTypes = keyMirror({
  GET_PAYMENTS_ASYNC_START: null,
  GET_PAYMENTS_ASYNC_SUCCESS: null,
  GET_PAYMENTS_ASYNC_ERROR: null,
  CHANGE_FILTER: null,
  CHANGE_SORTED: null,
  CHANGE_PAGE: null,
  CHANGE_PAGINATION: null,
});

export default {
  changeFilter: filter => dispatch =>
    dispatch({ type: actionTypes.CHANGE_FILTER, payload: filter }),
  getPayments: params => (dispatch, getState, api) => {
    const {
      partner: {
        offset,
        limit,
        filters: { sorted, ...rest },
      },
    } = getState();

    if (sorted.length) {
      const [sortedColumn] = sorted;
      params.orderBy = sortedColumn.id;
      params.orderMethod = sortedColumn.desc ? 'desc' : 'asc';
    }

    const mergedParams = {
      ...params,
      ...rest,
      limit,
      offset,
    };

    dispatch({ type: actionTypes.GET_PAYMENTS_ASYNC_START });

    return api.Partner.getPayments(mergedParams).then(
      payload => dispatch({ type: actionTypes.GET_PAYMENTS_ASYNC_SUCCESS, payload }),
      error => dispatch({ type: actionTypes.GET_PAYMENTS_ASYNC_ERROR, error }),
    );
  },
};
