const fetch = require('isomorphic-unfetch');

const buildURL = require('./buildURL');

const sendRequest = (method, url, opts = {}) => {
  const headers = Object.assign({}, opts.headers || {}, {
    'Content-type': 'application/json; charset=UTF-8',
  });

  return fetch(url, Object.assign({ method, credentials: 'same-origin' }, opts, { headers }));
};

const parseResponse = async response => {
  const parsedResponse = await response.json();
  if (response.ok) {
    return parsedResponse;
  }
  return Promise.reject(parsedResponse);
};

module.exports = {
  get: (url, params, headers) =>
    sendRequest('GET', buildURL(url, params), headers).then(parseResponse),
  del: (url, data, params) =>
    sendRequest('DELETE', buildURL(url, params), { body: JSON.stringify(data) }).then(
      parseResponse,
    ),
  put: (url, data, params) =>
    sendRequest('PUT', buildURL(url, params), { body: JSON.stringify(data) }).then(parseResponse),
  post: (url, data, params, headers) =>
    sendRequest('POST', buildURL(url, params), { body: JSON.stringify(data), headers }).then(
      parseResponse,
    ),
};
